$remove-color: #888;
$remove-hover-color: #555;
$remove-width: 20px;

$selection-color: $primary;


$focus-border-color: $border-color;

// $container-height: 45px;

$selection-bg-top-color: $white;
$selection-bg-bottom-color: $white;

$container-placeholder-color: $gray-600;

$container-focus-border-color: $border-color;

$selection-opened-bg-top-color: $selection-bg-bottom-color;
$selection-opened-bg-bottom-color: $selection-bg-top-color;

$dropdown-z-index: 1;

$dropdown-bg-color: $white;

$results-max-height: 200px;
$results-nested-padding: 20px;

$results-choice-bg-hover-color: rgba($primary, 0.1);
$results-choice-fg-hover-color: $primary;

$results-choice-fg-unselectable-color: $gray-300;
