.test-lobby {
    &__item {
        overflow: hidden;
        display        : flex;
        justify-content: flex-start;
        flex-wrap      : wrap;
        margin-bottom  : 1.875rem;

        .default-container__header {
            display        : flex;
            justify-content: center;
            align-items    : center;
            background     : $gray-50 url(../../images/triangle-pattern-down.png) repeat-x left bottom;
            background-size: 12px 12px;
            width          : 100%;
            padding-bottom: 1.5rem;

            img {
                height: 5.125rem;
            }

            @include media-breakpoint-up(lg) {
                width          : auto;
                min-width      : 360px;
                max-width      : 100%;
                background     : $gray-50 url(../../images/triangle-pattern-right.png) repeat-y right top;
                background-size: 12px 12px;

            }
        }

        .default-container__content {
            flex          : 1;
            padding-top   : 1.25em;
            padding-bottom: 1.25rem;

            @include media-breakpoint-up(lg) {
                padding-top   : 3em;
                padding-bottom: 3rem;
            }
        }
    }
}