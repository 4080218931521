.default-row-wrapper {
  display: flex;
  flex-wrap: wrap;

  &.is-vertical {
    display: flex;
    flex-flow: column wrap;
  }

  &.is-suggested-lesson {
    border: 1px solid $border-color;
  }

  &.is-added {

    .default-row__aside,
    .default-row {
      background-color: $light-green;
      border-color: darken($light-green, 15%);
    }
  }
}

.default-row-suggested-wrapper {
  background-color: $gray-50;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 1rem;
  width: 100%;
  margin-bottom: 0.625rem;

  &__header {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
    padding-top: 1rem;

    background: url(../../images/edusmart-character.png) no-repeat center top;

    background-size: auto 6rem;

    &-left {
      margin-bottom: 0.5rem;

      h4,
      .h4,
      h5,
      .h5 {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    padding-bottom: 0.5rem;

    &>.default-row {
      border: 2px dashed $orange  !important;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    &__header {
      background: url(../../images/edusmart-character.png) no-repeat left center;
      background-size: auto 4.5rem;
      text-align: left;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0 1.25rem 3.25rem;
      margin-bottom: 0;
    }
  }
}

//wrapper end

.default-row__aside {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 0.625rem;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background: $white;

  &-header {
    display: flex;
    align-items: center;
    padding: 0.625rem;
    border-right: 1px solid $border-color;
    font-size: 0.875rem;
    color: $gray-700;
    flex: 1;
    font-weight: bold;

    span {
      color: $gray-300;
      font-size: 0.75rem;
      align-self: center;
      margin-left: 0.5rem;
    }

    i {
      color: $gray-300;
      font-size: 1.25rem;
      align-self: center;
      padding-right: 0.25rem;
    }
  }

  &-content {
    flex: 1;
    padding: 0.75rem;
  }

  @include media-breakpoint-up(lg) {
    flex-flow: row wrap;
    justify-content: center;
    margin-left: 0.5rem;
    width: 150px;

    &-header {
      border-bottom: 1px solid $border-color;
      max-height: 3.75rem;
      padding: 0;
      border-right: none;

      i {
        margin-left: 0.45rem;
      }
    }

    &-content {
      display: flex;
      align-items: center;

      input {
        margin: 0 auto;
        max-width: 100px;
      }
    }
  }
}

.default-row {
  position: relative;
  $dr-parent: &;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: $white;
  margin-bottom: 0.625rem;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  &.is-lesson,
  &.is-activity {

    &-completed,
    &-selected {
      border-color: $green;
      box-shadow: inset 0 0 0 1px $green;
    }

    &-in-progress {
      border-color: $warning;
      box-shadow: inset 0 0 0 1px $warning;
    }
  }

  &.is-break {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: $gray-700;
    background: $white url("../../images/stripes-bg.svg") repeat-x left top;

    &:hover {
      background: $white url("../../images/stripes-bg.svg") repeat-x left top;
    }

    input[type="text"] {
      border: 0;
      background: $gray-200;
      margin-right: 0.25rem;

      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem 0.25rem 0rem;
      outline: 0;
      box-shadow: none;
      color: $gray-700;
      font-weight: bold;
      width: 3.25rem;
      text-align: center;

      &::placeholder {
        color: $primary;
      }
    }

    #{$dr-parent}__handle {
      line-height: 1;
      color: $gray-300;
      font-size: 1.25rem;
      margin-right: 0.5rem;
      transition: 200ms;

      &:hover {
        color: $primary;
      }
    }

    .workspace-new-lesson__remove-break {
      line-height: 1;
      color: $gray-300;
      font-size: 1.25rem;
      transition: 200ms;
      cursor: pointer;

      &:hover {
        color: $danger;
      }
    }
  }

  &.is-borderless {
    border: 0;
  }

  &.is-added {
    border-color: $primary;
    box-shadow: inset 0 0 0 1px $primary;
  }

  &__header,
  &__content,
  &__footer {
    flex: 1 0 100%;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem;
    border-bottom: 1px solid $border-color;
    min-height: 2.6875rem;

    flex-wrap: wrap;

    #{$dr-parent}__handle {
      position: absolute;
      top: 1rem;
      left: -2rem;
      line-height: 1;

      font-size: 1.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      margin-bottom: 0.25rem;

      width: 2rem;
      border-radius: 0.25rem 0 0 0.25rem;
      border: 1px solid $border-color;
      color: $gray-300;
      background: $gray-100;
      text-align: center;

      transition: 200ms;

      &:hover {
        color: $primary;
      }
    }

    #{$dr-parent}__description {
      display: inline-flex;
      flex-wrap: wrap;
      margin-right: auto;

      #{$dr-parent}__title {
        font-size: 1rem;
        font-weight: bold;
        margin-right: 0.5rem;
        color: $gray-700;
      }

      #{$dr-parent}__category {
        flex: 1 0 100%;
        align-self: center;
        font-size: 0.75rem;
        color: $gray-400;
        margin-right: 1rem;
        // margin-bottom: 0.5rem;
      }
    }

    #{$dr-parent}__activity-type {
      position: relative;
      font-size: 0.8125rem;
      font-weight: bold;
      color: $gray-700;
      align-self: center;
      padding-left: 1.5rem;
      padding-right: 0.5rem;

      i {
        position: absolute;
        left: 0;
        top: -1px;
        color: $gray-300;
        font-size: 1.25rem;
      }
    }

    @include media-breakpoint-up(lg) {
      //header
      justify-content: space-between;
      align-items: center;

      #{$dr-parent}__handle {
        position: relative;
        top: 0;
        left: 0;
        line-height: 1;
        color: $gray-300;
        font-size: 1.25rem;
        margin-right: 0.5rem;
        transition: 200ms;
        margin-right: 0.5rem;
        width: auto;
        border-radius: 0;
        border: none;
        background: transparent;
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;

        &:hover {
          color: $primary;
        }
      }

      #{$dr-parent}__description {
        #{$dr-parent}__category {
          font-size: 0.8125rem;
          margin-bottom: 0;
        }

        #{$dr-parent}__title {
          // font-size: 0.9375rem !important;
          font-size: 1.0625rem;
        }
      }

      #{$dr-parent}__activity-type {
        font-size: 0.8125rem;
      }

      #{$dr-parent}__description {
        max-width: 80%;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0.625rem;

    #{$dr-parent}__thumbnail {
      margin-bottom: 1rem;
    }

    #{$dr-parent}__introduction {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 1rem;
      margin-right: 1rem;
      width: 100%;

      h4,
      h5,
      h6 {
        margin-bottom: 0;
      }

      .form-row {
        display: flex;
        justify-content: center;

        [class^="col-"]:not(:first-child) {
          padding-top: 0.5rem;
          padding-left: 0.5rem;
          border: 1px solid $border-color;
          border-radius: $border-radius;
          margin-left: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      .reading-level {
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    #{$dr-parent}__features {
      margin-left: 0;
      flex: 0 0 auto;

      a {
        margin-right: 0.5rem;

        // &:last-child {
        //     margin-right: 0;
        // }
      }

      button {
        margin-right: 0.5rem;

        // &:last-child {
        //     margin-right: 0;
        // }
      }
    }

    @include media-breakpoint-up(md) {
      .form-row {
        [class^="col-"]:not(:first-child) {
          margin-bottom: 0;
          padding-bottom: 0;
          margin-left: 0;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      // padding        : 1rem 0.625rem;
      padding: 0.625rem;

      #{$dr-parent}__introduction {
        // font-size : 1rem;
        text-align: left;
        margin-bottom: 0;
        margin-right: 2rem;

        .progress-wrapper {
          position: relative;

          span {
            position: absolute;
            top: -1.75rem;
            right: 0;
          }
        }

        .form-row {
          justify-content: flex-start;
        }
      }

      #{$dr-parent}__thumbnail {
        margin-right: 1rem;
        margin-bottom: 0;
      }

      #{$dr-parent}__features {
        margin-left: auto;
      }
    }
  }

  &__class-list {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0;
    padding: 0;

    li {
      display: inline-flex;
      list-style: none;
      list-style-type: none;
      margin-right: 1.5rem;

      &:first-child {
        font-weight: 500;
        color: $primary;
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        max-height: 1.125rem;
        margin-right: 0.15rem;
      }
    }
  }

  &__footer {
    flex: 1 0 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem;
    border-top: 1px solid $border-color;
    font-size: 0.8125rem;

    a {
      color: $gray-700;
      font-weight: bold;

      &:hover {
        color: $primary;
      }
    }

    &.has-attachment {
      $tratra: &;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      border-top: 0;
    }
  }

  &__right,
  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;

    // height:100%;
  }

  &__left {
    flex: 1;
  }

  &__timeline-status {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    left: -5.2rem;
    width: 3.2rem;
    height: 3.2rem;
    transform-origin: center;
    transform: translateY(-50%);
    background: $white;
    border: 1px solid $border-color;
    border-radius: 10rem;
    color: $black;
    font-size: 0.9375rem;
    font-weight: bold;
    @include default-inset(-2px);

    i {
      font-size: 1.25rem;
    }
  }

  @include media-breakpoint-up(md) {
    &__right {
      margin-left: auto;
      justify-content: flex-end;
      flex: 1;
    }

    &__left {
      justify-content: flex-start;
      margin-right: auto;
    }

    &__class-list {
      justify-content: flex-start;
    }
  }

  @include media-breakpoint-up(lg) {
    &__introduction {

      .h5,
      h5 {
        max-width: 85%;
      }
    }
  }
}