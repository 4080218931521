.card-columns {

    column-count: 1;

    @include media-breakpoint-up(md) {
        column-count: 2;
    }

    @include media-breakpoint-up(lg) {
        column-count: 3;
    }

    @include media-breakpoint-up(xl) {
        column-count: 4;
    }

    &.is-wider {
        @include media-breakpoint-up(xl) {
            column-count: 3;
        }
    }
}

//welcome page card
.card {

    &-header {
        position: relative;
        .lesson-assign {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }

    &-footer {
        background: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {

            &:first-child {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: $gray-700;
                font-weight: bold;
                &:hover {
                    color: $primary;
                }
                i {
                    @extend %default-icon-style;
                    margin-right: 0.5rem;
                }
            }
            &:hover {
                text-decoration: none;
            }
        }
        
     

    }

    &.is-special {
        .card-header {

            padding-top    : 2rem;
            background     : $white url(../../images/card-is-welcome-bg.svg) center bottom 30px no-repeat;
            background-size: cover;
            border         : none;

            .thumbnail {
                margin    : 0 auto;
                box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
            }
        }

        .card-body {
            padding-top: 0.5rem;
            text-align : center;
        }

        .card-title {
            margin-bottom: 0;
        }

        .card-footer {
            background: $white;
            flex-wrap:wrap;
            a {

                display      : block;
                color        : $gray-700;
                font-weight  : bold;
                margin-bottom: 0.35rem;
                margin-top   : 0.35rem;

                &:hover {
                    color: $primary;
                }

            }
        }
    }
}