.edusmart-preview {

    padding-bottom: 4rem;

    &__intro-video {
        box-shadow   : 0 9px 26px -5px rgba(0, 0, 0, 0.50);
        border-radius: $border-radius /2;
        background   : $gray-200;
    }

    h6,
    .h6 {
        margin-bottom: 0;
    }

    .default-container {
        &__header {
            padding: 1.25rem;
        }
    }

    .accordion-toggler {
        display  : flex;
        flex     : 1 1 95%;
        max-width: 95%;

        &::after {
            content    : "\e93b";
            font-family: $edusmart-font-family;
            font-size  : 1.25rem;
            color      : $secondary;
            position   : absolute;
            top        : 1rem;
            right      : 1rem;
        }

        &[aria-expanded="true"] {
            &::after {
                content: "\e938";
            }
        }
    }


    &__testimonials {

        &-slider {

            /* the slides */
            .slick-slide {
                margin: 0 10px;
            }

            /* the parent */
            .slick-list {
                margin: 0 -10px;
            }

            .embed-responsive {
                margin-bottom: 1rem;
            }

        }

        &-slide {}

        &-person {
        }

        &-favs {
            margin : 0;
            padding: 0;

            li {
                position  : relative;
                list-style: none;

                &::before {
                    content     : "\e933";
                    position    : relative;
                    top         : 3px;
                    font-family : $edusmart-font-family;
                    color       : $primary;
                    font-size   : 1.3125rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }

    &__nav {
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        padding:1em 0;
        background: $white;
        border-top: 1px solid $border-color;
        box-shadow   : 0 -5px 5px 0 rgba(0, 0, 0, 0.12);
        z-index:1000;
    }
}