.student-progress {
    display        : flex;
    flex-flow      : row wrap;
    justify-content: center;
    align-items    : center;
    flex: 0 0 100%;

    &__progressbar {
        flex: 1;

    }

    &__output {
        padding-left: 0.5rem;
        text-align  : center;

        h3 {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 1.5rem;
        }
    }
}