.track-assessments {
 
    .breadcrumb {
        margin-bottom: 0;
    }

    .default-row {
        &__content {
            padding:1.25rem;
            justify-content: space-between;
            h6 {
                margin-bottom: 0;
                
            }
        }
    }
}