.teacher-dashboard {
  &__header {
    background: $tertiary;
    padding: 1rem 0;
    margin-bottom: 1rem;

    .subtitle {
      color: rgba($white, 0.75);
    }
  }

  &__person {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;

    &-avatar {
      height: 4rem;
      width: 4rem;

      img {
        border-radius: $border-radius-lg * 2;
        max-width: 100%;
        pointer-events: none;
      }
    }

    &-details {
    }
  }

  &__grade {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;

    .dropdown-toggle {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-left: auto;
      margin-right: auto;

      &::after {
        display: none;
      }

      i {
        position: relative;
        top: 12px;
        color: $white;
        font-family: $edusmart-font-family;
        font-size: 1.5rem;
        line-height: 1;
      }
      &[aria-expanded="true"] {
        i {
          &::before {
            content: $icon-settings-2-outline;
          }
        }
      }
    }

    &-icon {
      text-align: center;
      width: 3rem;
      height: 3rem;
      background-color: rgba($black, 0.25);
      border-radius: $border-radius-lg * 2;
    }

    &-description {
      display: flex;
      flex-flow: column wrap;
      text-align: center;
      margin-right: 1rem;

      span {
        display: block;
      }
    }
  }

  &__track {
    margin: 0;
    padding: 0;

    &-item {
      list-style: none;
      margin-bottom: 1rem;

      h6,
      .h6 {
        display: block;
        margin-bottom: 0.25rem;
      }

      &-footer {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .default-container {
    &__header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      &-controls {
        .btn-rounded {
          &[aria-expanded="true"] {
            i {
              &::before {
                content: $icon-chevron-up-outline;
              }
            }
          }
        }
      }
    }
  }
  .teacher-dashboard-videos-slider {
    margin-bottom: 0 !important;
    /* the slides */
    /* the parent */
  }
  .teacher-dashboard-videos-slider .slick-slide {
    margin: 0 8px;
  }
  .teacher-dashboard-videos-slider .slick-list {
    margin: 0 -8px;
  }
  .teacher-dashboard-videos-slider .tile-dashboard__icon {
    color: #414141;
  }
  .teacher-dashboard-videos-slider h6,
  .teacher-dashboard-videos-slider .h6 {
    line-height: 1.2;
  }
  .teacher-dashboard-videos-slider__dots {
    position: relative;
    min-height: 1rem;
    margin-bottom: 1rem;
  }
  .teacher-dashboard-videos-slider__dots .slick-dots {
    position: relative;
    bottom: auto;
    top: auto;
  }
  .teacher-dashboard-videos-slider__dots .slick-dots button::before {
    font-size: 2rem !important;
    line-height: 0.25;
  }
  .teacher-dashboard-videos-slider__dots
    .slick-dots
    li.slick-active
    button::before {
    color: #ff6500 !important;
  }
  .teacher-dashboard-videos-slider__dots .slick-dots li:hover {
    color: #ff6500;
  }

  @include media-breakpoint-up(md) {
    &__header {
      padding: 2rem 0;
      margin-bottom: 2rem;
    }

    &__person {
      flex-flow: row nowrap;
      text-align: left;
      width: auto;
      margin-bottom: 0;

      &-avatar {
        margin-right: 1rem;
      }
    }

    &__grade {
      margin-left: auto;
      width: auto;

      &-description {
        text-align: right;
      }
    }
  }
}
