.overlay {
    position       : fixed;
    display        : flex;
    z-index        : 9999;
    background     : rgba($white, 0.98);
    top            : 0;
    left           : 0;
    bottom         : 0;
    right          : 0;
    width          : 100%;
    height         : 100%;
    justify-content: center;
    align-items    : center;
    text-align     : center;

    &.is-hidden {
        display: none;
    }

    &.is-visible {
        display: flex;
    }

}