.nav-filters {
    $nav-filter-parent: &;
    display           : flex;
    flex-flow         : row wrap;
    margin            : 0 0 1rem 0;
    padding           : 0;

    &__item {
        position       : relative;
        display        : flex;
        justify-content: center;
        align-items    : center;
        padding        : 0;
        text-align     : center;
        margin-bottom  : 0.625rem;

        flex     : 0 0 100%;
        max-width: 100%;

    
        img {
            max-height: 2.75rem;
            margin-bottom: 0.35rem;
            text-align: center;
        }


        &.is-active {
            overflow: hidden;

            #{$nav-filter-parent}__link {

                border-color: $primary;
                color       : $primary;

                &::after {
                    content    : $icon-checkmark-outline;
                    font-family: $edusmart-font-family;
                    position   : absolute;
                    top        : 3px;
                    right      : 4px;
                    color      : $white;
                    font-size  : 1rem;
                    transform  : scale(1);
                }

                &::before {
                    content     : "";
                    position    : absolute;
                    top         : 0;
                    right       : 0;
                    width       : 0;
                    height      : 0;
                    transform   : translate(0px, 0px) scale(1);
                    border-style: solid;
                    border-width: 0 36px 36px 0;
                    border-color: transparent $primary transparent transparent;

                }

                i {
                    color: $primary;
                }
            }

        }
    }

    &__link {
        z-index        : 1;
        display        : flex;
        justify-content: flex-start;
        align-items    : center;
        flex-flow      : row nowrap;
        width          : 100%;
        height         : 100%;
        font-weight    : 600;
        padding        : 0.85rem 0.5rem;
        border         : 3px solid transparent;
        border-radius  : 8px;
        line-height    : 1.1;
        color          : $gray-700;
        font-size      : 0.9333rem;
        transition     : $transition-base;

        i {

            font-family : $edusmart-font-family;
            font-size   : 2rem;
            margin-right: 0.5rem;
            color       : $primary;

        }

        &:hover {
            text-decoration: none;
            color          : $primary;

            i {
                color: $primary;
            }
        }

        &::before {
            content   : "";
            transition: $transition-base;
            transform : translate(40px, -40px) scale(0);
        }
    }


    @include media-breakpoint-up(sm) {

        &__item {
            max-width: 49%;
            flex     : 0 1 49%;

            &:nth-child(odd) {
                margin-right: 0.5rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        flex-flow: row wrap;

        &__item {
            flex        : 0 1 18%;
            margin-right: 0.5rem;

            #{$nav-filter-parent}__link {
                justify-content: center;
                align-items    : center;
                flex-flow      : column nowrap;
                padding        : 1.2rem 0.4rem;

                i {
                    padding-bottom: 0.25rem;
                    margin-right  : 0;
                    font-size: 2rem;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        justify-content: space-evenly;
        align-items    : stretch;
        align-content  : center;
        flex-flow      : row nowrap;
        margin         : 0 0 2rem 0;

        &__item {
            padding: 0;
            margin : 0 0.625rem 0 0;

            &:last-child {
                margin-right: 0;
            }

            #{$nav-filter-parent}__link {
                font-size: 0.9375rem;

                i {
                    font-size: 2.5rem;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__item {
            font-size: 1rem;
        }
    }
}