.inset-section {

    position       : relative;
    width          : 100%;
    padding        : 0;
    background     : $gray-50 url(../../images/triangle-pattern.png) repeat-x left top;
    background-size: 12px 12px;
    border-bottom  : 1px solid $border-color;

    &.is-bottom {
        background     : $gray-50 url(../../images/triangle-pattern-down.png) repeat-x left bottom;
        background-size: 12px 12px;
        border-top     : 1px solid $border-color;
        border-bottom  : none;
    }

    &.is-right {
        background     : $gray-50 url(../../images/triangle-pattern-right.png) repeat-y right top;
        background-size: 12px 12px;
        border         : none;
    }

    &.is-left {
        background     : $gray-50 url(../../images/triangle-pattern-left.png) repeat-y left top;
        background-size: 12px 12px;
        border         : none;
    }
    .has-padding {
        padding: 2rem 1rem 1rem 1rem;
    }
}