.tile {
  &-dashboard {
    &__item {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: space-around;
      min-height: 160px;
      padding: 0.75rem;
      transition: $transition-base;
      margin-bottom: 1.25rem;

      .tile-dashboard__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        font-family: $edusmart-font-family;
        font-size: 2.5rem;
        color: $primary;
        padding: 0.5rem 0;
      }
      h5,
      .h5 {
        font-size: 1rem;
      }
      &:hover {
        text-decoration: none;
        box-shadow: 0 2px 0 0 $border-color, $box-shadow;

        .h5 {
          color: $primary;
        }
      }
    }
    &__inner-wrapper {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
      justify-content: flex-start;
      padding-top: 0.75rem;
      padding-bottom: 1.25rem;
    }
    &__anchor {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding-top: 0.75rem;
    }
    &__anchor::before {
      content: "";
      position: absolute;
      top: 0;
      left: -0.75rem;
      right: -0.75rem;
      height: 1px;
      background: #e7e7e7;
    }

    &__label {
      display: block;
      text-align: center;

      span {
        display: block;
        width: 100%;
      }

      .h5 {
        line-height: 1.2;
        transition: $transition-base;
      }
    }

    @include media-breakpoint-up(sm) {
      &__item {
        margin-bottom: 1.75rem;
        min-height: 180px;

        h5,
        .h5 {
          font-size: 1.0625rem;
        }
        .admin-dashboard__icon {
          font-size: 3.25rem;
          padding: 0.75rem 0;
        }
      }
    }
  }
}
