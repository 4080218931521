/*! nouislider - 14.0.3 - 10/10/2019 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */


.noUi-target,
.noUi-target * {
    -webkit-touch-callout      : none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select        : none;
    -ms-touch-action           : none;
    touch-action               : none;
    -ms-user-select            : none;
    -moz-user-select           : none;
    user-select                : none;
    -moz-box-sizing            : border-box;
    box-sizing                 : border-box;
    border-radius              : 24rem;
}

.noUi-target {
    position : relative;
    direction: ltr;
}

.noUi-base,
.noUi-connects {
    width     : 100%;
    height    : 100%;
    position  : relative;
    z-index   : 1;
    background: $gray-100;

}

/* Wrapper for all connect elements.
  */
.noUi-connects {
    overflow: hidden;
    z-index : 0;

}

.noUi-connect,
.noUi-origin {
    will-change             : transform;
    position                : absolute;
    z-index                 : 1;
    top                     : 0;
    left                    : 0;
    -ms-transform-origin    : 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style : preserve-3d;
    transform-origin        : 0 0;
    transform-style         : flat;
}

.noUi-connect {
    height: 100%;
    width : 100%;
}

.noUi-origin {
    height: 10%;
    width : 10%;
}

/* Offset direction
  */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
    left : auto;
    right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
.noUi-vertical .noUi-origin {
    width: 0;
}

.noUi-horizontal .noUi-origin {
    height: 0;
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility        : hidden;
    position                   : absolute;
}

.noUi-touch-area {
    height: 100%;
    width : 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition        : transform 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

/* Slider size and handle placement;
  */
.noUi-horizontal {
    height: 1rem;
}

.noUi-horizontal .noUi-handle {
    width : 2rem;
    height: 2rem;
    left  : -1rem;
    top   : -0.5rem;
}

.noUi-vertical {
    width: 18px;
}

.noUi-vertical .noUi-handle {

    width : 34px;
    height: 28px;
    left  : -17px;
    top   : -6px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -17px;
    left : auto;
}

/* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */
.noUi-target {
    background-color: none;
    border-radius   : 24rem;
    border          : 1px solid $border-color;
    box-shadow      : inset 0 0 0 2px $white;
}

.noUi-connects {
    border-radius: $border-radius-lg
}

.noUi-connect {
    position        : relative;
    background-color: $success;
    box-shadow      : inset 0 0 0 1px $white, inset 0 -2px 2px 0 rgba(0, 0, 0, .2);
    border-radius   : 24rem;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size : 0.8rem 0.8rem;
    animation       : progress-bar-stripes 1.5s linear infinite;

}

/* Handles and cursors;
  */
.noUi-draggable {
    cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}

.noUi-handle {
    border       : 1px solid $border-color;
    border-radius: 24rem;
    background   : $white;
    cursor       : default;
    // box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow   : inset 0 -2px 0px rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.2);

    //    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
    &               :focus {
        outline   : none;
        border    : 1px solid $border-color;
        box-shadow: inset 0 -2px 0px rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }
}

.noUi-active {
    //    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Handle stripes;
  */
.noUi-handle:before,
.noUi-handle:after {
    content      : "";
    display      : block;
    position     : absolute;
    height       : 14px;
    width        : 1px;
    background   : $border-color;
    left         : 12px;
    top          : 7px;
    border-radius: 9999px;
}

.noUi-handle:after {
    left: 16px;
}

//  .noUi-vertical .noUi-handle:before,
//  .noUi-vertical .noUi-handle:after {
//    width : 14px;
//    height: 1px;
//    left  : 6px;
//    top   : 14px;
//  }
//  .noUi-vertical .noUi-handle:after {
//    top: 17px;
//  }
/* Disabled state;
  */
[disabled] .noUi-connect {
    background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
    cursor: not-allowed;
}

/* Base;
  *
  */
.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing     : border-box;
}

.noUi-pips {
    position: absolute;
    color   : $gray-400;

}

/* Values;
  *
  */
.noUi-value {
    position   : absolute;
    white-space: nowrap;
    text-align : center;
}

.noUi-value-sub {
    color    : $gray-400;
    font-size: 10px;
}

/* Markings;
  *
  */
.noUi-marker {
    position  : absolute;
    background: $gray-300;
}

.noUi-marker-sub {
    background: $gray-300;
}

.noUi-marker-large {
    background: $gray-300;
}

/* Horizontal layout;
  *
  */
.noUi-pips-horizontal {
    padding: 10px 0;
    height : 80px;
    top    : 100%;
    left   : 0;
    width  : 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform        : translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform        : translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width      : 2px;
    height     : 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}

/* Vertical layout;
  *
  */
.noUi-pips-vertical {
    padding: 0 10px;
    height : 100%;
    top    : 0;
    left   : 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform        : translate(0, -50%);
    padding-left     : 25px;
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform        : translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
    width     : 5px;
    height    : 2px;
    margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}

.noUi-tooltip {
    display      : block;
    position     : absolute;
    // border    : 1px solid #D9D9D9;
    border-radius: 3px;
    background   : $gray-700;
    color        : $white;
    padding      : 5px;
    text-align   : center;
    white-space  : nowrap;
    font-size    : $font-size-sm;
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform        : translate(-50%, 0);
    left             : 50%;
    bottom           : 120%;
    // left          : 200%;
    // bottom        : 0%;
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform        : translate(0, -50%);
    top              : 50%;
    right            : 120%;
}