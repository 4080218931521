.nav-pills {

    .nav-item {
        margin-right : 0.5rem;
        margin-bottom: 0.5rem;

        &:last-child {
            margin-right: 0;
        }

        .nav-link {
            border   : 2px solid $gray-200;
            font-size: 0.875rem;

            &.active {
                border          : 2px solid $primary;
                background-color: $primary;
            }

            &>.badge {
                margin-left: 0.3rem;
                padding    : 0rem 0.5rem !important;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .nav-item {
            margin-bottom: 0;

            .nav-link {
                font-size: 1rem;
            }
        }
    }
}

.nav-flex-wrap {
    flex-wrap: nowrap !important;
}

.nav-tabs {
    &__link {
        display    : inline-flex;
        align-items: center;
        color      : $gray-700;
        font-weight: bold;

        i {
            color: $gray-700;
            @extend %default-icon-style;
        }

        &:hover {
            color          : $primary;
            text-decoration: none;

            i {
                color: $primary;
            }
        }
    }



    .nav-link {
        &:hover {
            background-color: darken($gray-200, 3%);
        }
    }


    .nav-item {
        margin-right: 0.5rem;
    }
    .nav-link {
        position       : relative;
        display        : flex;
        justify-content: flex-start;
        align-items    : center;
        padding        : 1rem 0.5rem;
        background     : $gray-200;
        margin-right   : 0.3333rem;
        font-size      : 0.85rem;
        font-weight    : 600;
        color          : $gray-700;
        @include default-inset(-2px, 8%);

        img {
            height      : 2rem;
            margin-right: 0.5rem;
        }

        &:last-child {
            margin-right: 0;
        }

        //hide on mobile
        i,
        .badge {
            display: none;
        }

        &.active {
            background: $white;
            @include default-inset(0);
            border       : 1px solid $border-color;
            border-bottom: none;

            i {
                color: $gray-300;
            }
        }

        @include media-breakpoint-up(sm) {
            font-size: 0.9333rem;
            padding  : 1rem 1rem;

            &.has-badge {

                .badge {
                    display    : inline-flex;
                    padding    : 0.35rem 0.7rem;
                    margin-left: 0.6667rem;
                }
            }

            i {
                display    : inline-block;
                font-size  : 1.5rem;
                color      : $gray-700;
                line-height: 1;
                padding    : 0 0.5rem 0 0;
                margin     : 0;
            }

        }

        @include media-breakpoint-up(lg) {
            img {
                margin-right: 1rem;
            }
        }

    }

    .nav-item {
        &.active {
            &::before {
                content   : "";
                position  : absolute;
                bottom    : -2px;
                left      : 0;
                right     : 0;
                width     : 100%;
                height    : 2px;
                background: $white;
                z-index   : 1;
            }
        }
    }
}
