

    .workspace {


        &-new-lesson {

            &__initial-properties {
                @include media-breakpoint-down(md) {
                    .col-12 {
                        margin-bottom: 0.75rem;
                    }
                }
            }

            &__instructions {

                .default-container {

                    &__header {
                        display        : flex;
                        justify-content: space-between;
                        align-items    : center;
                        padding        : 0.75rem;


                        @include media-breakpoint-up(lg) {
                            padding: 1.25rem;
                        }

                        h5 {
                            margin-bottom : 0;
                            padding-bottom: 0;
                            line-height   : 1;
                        }

                    }

                    &__content {

                        margin : 0;
                        padding: 0 1rem 0 1rem;

                        @include media-breakpoint-up(lg) {
                            padding: 0 2rem 0 2rem;
                        }
                    }
                }
            }

            &__upload-plan {
                display        : flex;
                justify-content: space-between;
                align-items    : center;
                flex-wrap      : wrap;
                padding-top    : 1.75rem;

                &-copy {
                    flex         : 1 0 100%;
                    display      : flex;
                    align-items  : center;
                    flex-flow    : row nowrap;
                    margin-bottom: 1rem;

                    span {
                        max-width: 80%;
                    }

                }

                &-features {
                    flex      : 0 0 100%;
                    text-align: center;
                }

                @include media-breakpoint-up(lg) {
                    justify-content: flex-start;

                    &-copy {
                        flex         : 1 1 55%;
                        margin-right : 0;
                        margin-bottom: 0;

                    }

                    &-features {
                        flex: 0 0 auto;
                    }
                }
            }

            &__playlist {
                #workspace-break-label {
                    min-width    : 10rem;
                    padding-left : 0.5rem;
                    padding-right: 0.5rem;
                }

                // #workspace-break-time {}

                .default-container__header {
                    display    : flex;
                    align-items: center;
                    flex-wrap  : wrap;

                    .workspace-new-lesson__playlist-copy {
                        flex         : 1 0 100%;
                        margin-bottom: 1rem;
                    }

                    .workspace-new-lesson__playlist-features {
                        text-align: center;
                        flex      : 1 0 100%;
                    }

                    @include media-breakpoint-up(lg) {
                        .workspace-new-lesson__playlist-copy {
                            flex         : 2;
                            margin-bottom: 0;
                        }

                        .workspace-new-lesson__playlist-features {
                            text-align: right;
                            flex      : 1;
                        }
                    }
                }
            }

            &__assign {
                &-grouped-form {
                    display        : flex;
                    justify-content: space-between;
                    align-items    : center;
                    flex-flow      : row nowrap;
                    border         : 2px solid $border-color;
                    border-radius  : $border-radius;
                    padding        : 0.5rem;
                    margin-bottom  : 1rem;

                    i {
                        font-size: 1.25rem;
                        color    : $gray-300;
                        cursor   : pointer;
                    }

                    &.is-active {
                        border-color: $primary;
                    }

                    label {
                        top: 5px;
                    }

                    @include media-breakpoint-up(lg) {
                        margin-bottom: 0;
                    }
                }
            }

            &__note {
                min-height: 85px;
            }

            &__add_note-toggler {
                position       : relative;
                display        : flex;
                justify-content: flex-end;
                padding-top    : 0.625rem;
                font-weight    : bold;
                margin-left    : auto;

                &:hover {
                    text-decoration: none;
                }

                i {
                    font-size: 1.25rem;
                }
            }
        }

        &__attachments {
            position: relative;
            width   : 100%;
            padding : 0;

            // &.show {
            //     width: 100%;

            //     &::before {
            //         content   : "";
            //         position  : absolute;
            //         top       : -1px;
            //         left      : 0;
            //         right     : 0;
            //         height    : 1px;
            //         background: $white;
            //         width     : 100%;
            //     }
            // }

            &-toggler {
                display    : block;
                text-align : center;
                width      : 100%;
                padding-top: 0.75em;
                font-weight: bold;
                color      : $gray-700;
                border-top : 1px solid $border-color;
            }

            &-list {
                background        : $gray-50 url(../../images/triangle-pattern.png) repeat-x left top;
                background-size   : 12px 12px;
                padding           : 1.5rem 1rem 0rem 1rem;
                // border-bottom  : 1px solid $border-color;
            }
        }

        // &__associated-students {
        //     flex: 1 0 100%;
        // }
        // &__associated-teachers {
        //     flex: 1 0 100%;
        // }
        // &__associated-subgroups {
        //     flex: 1 0 100%;
        // }
    }
    .add-new-subfolder {
        position: relative;

        label {
            padding-left: 30px;
        }

        &::before {
            content: "\e94b";
            font-family: $edusmart-font-family;
            font-size: 1.5rem;
            color: $gray-300;
            position: absolute;
            left:0;
            top:-6px;
        }
    }
