.teacher-dashboard-videos-slider {
    margin-bottom: 0 !important;

    /* the slides */
    .slick-slide {
        margin: 0 8px;
    }

    /* the parent */
    .slick-list {
        margin: 0 -8px;
    }

    .tile-dashboard__icon {
        color: $body-color;
    }

    .tile-dashboard__item {
        // margin-bottom: 0.5rem;
    }

    h6,
    .h6 {
        line-height: 1.2;
    }

    &__dots {
        position     : relative;
        min-height   : 1rem;
        margin-bottom: 1rem;

        .slick-dots {
            position: relative;
            bottom  : auto;
            top     : auto;

            button {

                &::before {
                    font-size  : 2rem !important;
                    line-height: 0.25;
                }
            }

            li {
                &.slick-active {
                    button::before {
                        color: $primary !important;
                    }
                }

                &:hover {
                    color: $primary;
                }
            }

        }
    }

}