$edusmart-font-family: "edusmart" !default;
$edusmart-icon-path: "../fonts/edusmart" !default;

$icon-youtube-outline: "\e9fc";
$icon-one-drive-outline: "\ea02";
$icon-interaction: "\ea03";
$icon-google-drive-outline: "\ea04";
$icon-clipboard-add-outline: "\ea00";
$icon-home-add-outline: "\ea01";
$icon-bookshelf: "\e9fe";
$icon-play: "\e9ff";
$icon-logged-in-user: "\e9fd";
$icon-hands-on-science: "\e9fb";
$icon-assign-clipboard: "\e9fa";
$icon-on-the-playlist: "\e9f6";
$icon-add-to-playlist: "\e9f7";
$icon-add-to-book: "\e9f8";
$icon-in-the-book: "\e9f9";
$icon-eye: "\e900";
$icon-trending-up-2: "\e901";
$icon-line-chart: "\e902";
$icon-activity-outline: "\e903";
$icon-alert-circle-outline: "\e904";
$icon-alert-triangle-outline: "\e905";
$icon-archive-outline: "\e906";
$icon-arrow-back-outline: "\e907";
$icon-arrow-circle-down-outline: "\e908";
$icon-arrow-circle-left-outline: "\e909";
$icon-arrow-circle-right-outline: "\e90a";
$icon-arrow-circle-up-outline: "\e90b";
$icon-arrow-down-outline: "\e90c";
$icon-arrow-downward-outline: "\e90d";
$icon-arrow-forward-outline: "\e90e";
$icon-arrow-ios-back-outline: "\e90f";
$icon-arrow-ios-downward-outline: "\e910";
$icon-arrow-ios-forward-outline: "\e911";
$icon-arrow-ios-upward-outline: "\e912";
$icon-arrow-left-outline: "\e913";
$icon-arrow-right-outline: "\e914";
$icon-arrow-up-outline: "\e915";
$icon-arrow-upward-outline: "\e916";
$icon-arrowhead-down-outline: "\e917";
$icon-arrowhead-left-outline: "\e918";
$icon-arrowhead-right-outline: "\e919";
$icon-arrowhead-up-outline: "\e91a";
$icon-at-outline: "\e91b";
$icon-attach-2-outline: "\e91c";
$icon-attach-outline: "\e91d";
$icon-award-outline: "\e91e";
$icon-backspace-outline: "\e91f";
$icon-bar-chart-2-outline: "\e920";
$icon-bar-chart-outline: "\e921";
$icon-battery-outline: "\e922";
$icon-behance-outline: "\e923";
$icon-bell-off-outline: "\e924";
$icon-bell-outline: "\e925";
$icon-bluetooth-outline: "\e926";
$icon-book-open-outline: "\e927";
$icon-book-outline: "\e928";
$icon-bookmark-outline: "\e929";
$icon-briefcase-outline: "\e92a";
$icon-browser-outline: "\e92b";
$icon-brush-outline: "\e92c";
$icon-bulb-outline: "\e92d";
$icon-calendar-outline: "\e92e";
$icon-camera-outline: "\e92f";
$icon-car-outline: "\e930";
$icon-cast-outline: "\e931";
$icon-charging-outline: "\e932";
$icon-checkmark-circle-2-outline: "\e933";
$icon-checkmark-circle-outline: "\e934";
$icon-checkmark-outline: "\e935";
$icon-checkmark-square-2-outline: "\e936";
$icon-checkmark-square-outline: "\e937";
$icon-chevron-down-outline: "\e938";
$icon-chevron-left-outline: "\e939";
$icon-chevron-right-outline: "\e93a";
$icon-chevron-up-outline: "\e93b";
$icon-clipboard-outline: "\e93c";
$icon-clock-outline: "\e93d";
$icon-close-circle-outline: "\e93e";
$icon-close-outline: "\e93f";
$icon-close-square-outline: "\e940";
$icon-cloud-download-outline: "\e941";
$icon-cloud-upload-outline: "\e942";
$icon-code-download-outline: "\e943";
$icon-code-outline: "\e944";
$icon-collapse-outline: "\e945";
$icon-color-palette-outline: "\e946";
$icon-color-picker-outline: "\e947";
$icon-compass-outline: "\e948";
$icon-copy-outline: "\e949";
$icon-corner-down-left-outline: "\e94a";
$icon-corner-down-right-outline: "\e94b";
$icon-corner-left-down-outline: "\e94c";
$icon-corner-left-up-outline: "\e94d";
$icon-corner-right-down-outline: "\e94e";
$icon-corner-right-up-outline: "\e94f";
$icon-corner-up-left-outline: "\e950";
$icon-corner-up-right-outline: "\e951";
$icon-credit-card-outline: "\e952";
$icon-crop-outline: "\e953";
$icon-cube-outline: "\e954";
$icon-diagonal-arrow-left-down-outline: "\e955";
$icon-diagonal-arrow-left-up-outline: "\e956";
$icon-diagonal-arrow-right-down-outline: "\e957";
$icon-diagonal-arrow-right-up-outline: "\e958";
$icon-done-all-outline: "\e959";
$icon-download-outline: "\e95a";
$icon-droplet-off-outline: "\e95b";
$icon-droplet-outline: "\e95c";
$icon-edit-2-outline: "\e95d";
$icon-edit-outline: "\e95e";
$icon-email-outline: "\e95f";
$icon-expand-outline: "\e960";
$icon-external-link-outline: "\e961";
$icon-eye-off-2-outline: "\e962";
$icon-eye-off-outline: "\e963";
$icon-eye-outline: "\e964";
$icon-facebook-outline: "\e965";
$icon-file-add-outline: "\e966";
$icon-file-outline: "\e967";
$icon-file-remove-outline: "\e968";
$icon-file-text-outline: "\e969";
$icon-film-outline: "\e96a";
$icon-flag-outline: "\e96b";
$icon-flash-off-outline: "\e96c";
$icon-flash-outline: "\e96d";
$icon-flip-2-outline: "\e96e";
$icon-flip-outline: "\e96f";
$icon-folder-add-outline: "\e970";
$icon-folder-outline: "\e971";
$icon-folder-remove-outline: "\e972";
$icon-funnel-outline: "\e973";
$icon-gift-outline: "\e974";
$icon-github-outline: "\e975";
$icon-globe-2-outline: "\e976";
$icon-globe-outline: "\e977";
$icon-google-outline: "\e978";
$icon-grid-outline: "\e979";
$icon-hard-drive-outline: "\e97a";
$icon-hash-outline: "\e97b";
$icon-headphones-outline: "\e97c";
$icon-heart-outline: "\e97d";
$icon-home-outline: "\e97e";
$icon-image-outline: "\e97f";
$icon-inbox-outline: "\e980";
$icon-info-outline: "\e981";
$icon-keypad-outline: "\e982";
$icon-layers-outline: "\e983";
$icon-layout-outline: "\e984";
$icon-link-2-outline: "\e985";
$icon-link-outline: "\e986";
$icon-linkedin-outline: "\e987";
$icon-list-outline: "\e988";
$icon-loader-outline: "\e989";
$icon-lock-outline: "\e98a";
$icon-log-in-outline: "\e98b";
$icon-log-out-outline: "\e98c";
$icon-map-outline: "\e98d";
$icon-maximize-outline: "\e98e";
$icon-menu-2-outline: "\e98f";
$icon-menu-arrow-outline: "\e990";
$icon-menu-outline: "\e991";
$icon-message-circle-outline: "\e992";
$icon-message-square-outline: "\e993";
$icon-mic-off-outline: "\e994";
$icon-mic-outline: "\e995";
$icon-minimize-outline: "\e996";
$icon-minus-circle-outline: "\e997";
$icon-minus-outline: "\e998";
$icon-minus-square-outline: "\e999";
$icon-monitor-outline: "\e99a";
$icon-moon-outline: "\e99b";
$icon-more-horizontal-outline: "\e99c";
$icon-more-vertical-outline: "\e99d";
$icon-move-outline: "\e99e";
$icon-music-outline: "\e99f";
$icon-navigation-2-outline: "\e9a0";
$icon-navigation-outline: "\e9a1";
$icon-npm-outline: "\e9a2";
$icon-options-2-outline: "\e9a3";
$icon-options-outline: "\e9a4";
$icon-pantone-outline: "\e9a5";
$icon-paper-plane-outline: "\e9a6";
$icon-pause-circle-outline: "\e9a7";
$icon-people-outline: "\e9a8";
$icon-percent-outline: "\e9a9";
$icon-person-add-outline: "\e9aa";
$icon-person-delete-outline: "\e9ab";
$icon-person-done-outline: "\e9ac";
$icon-person-outline: "\e9ad";
$icon-person-remove-outline: "\e9ae";
$icon-phone-call-outline: "\e9af";
$icon-phone-missed-outline: "\e9b0";
$icon-phone-off-outline: "\e9b1";
$icon-phone-outline: "\e9b2";
$icon-pie-chart-outline: "\e9b3";
$icon-pin-outline: "\e9b4";
$icon-play-circle-outline: "\e9b5";
$icon-plus-circle-outline: "\e9b6";
$icon-plus-outline: "\e9b7";
$icon-plus-square-outline: "\e9b8";
$icon-power-outline: "\e9b9";
$icon-pricetags-outline: "\e9ba";
$icon-printer-outline: "\e9bb";
$icon-question-mark-circle-outline: "\e9bc";
$icon-question-mark-outline: "\e9bd";
$icon-radio-button-off-outline: "\e9be";
$icon-radio-button-on-outline: "\e9bf";
$icon-radio-outline: "\e9c0";
$icon-recording-outline: "\e9c1";
$icon-refresh-outline: "\e9c2";
$icon-repeat-outline: "\e9c3";
$icon-rewind-left-outline: "\e9c4";
$icon-rewind-right-outline: "\e9c5";
$icon-save-outline: "\e9c6";
$icon-scissors-outline: "\e9c7";
$icon-search-outline: "\e9c8";
$icon-settings-2-outline: "\e9c9";
$icon-settings-outline: "\e9ca";
$icon-shake-outline: "\e9cb";
$icon-share-outline: "\e9cc";
$icon-shield-off-outline: "\e9cd";
$icon-shield-outline: "\e9ce";
$icon-shopping-bag-outline: "\e9cf";
$icon-shopping-cart-outline: "\e9d0";
$icon-shuffle-2-outline: "\e9d1";
$icon-shuffle-outline: "\e9d2";
$icon-skip-back-outline: "\e9d3";
$icon-skip-forward-outline: "\e9d4";
$icon-slash-outline: "\e9d5";
$icon-smartphone-outline: "\e9d6";
$icon-speaker-outline: "\e9d7";
$icon-square-outline: "\e9d8";
$icon-star-outline: "\e9d9";
$icon-stop-circle-outline: "\e9da";
$icon-sun-outline: "\e9db";
$icon-swap-outline: "\e9dc";
$icon-sync-outline: "\e9dd";
$icon-text-outline: "\e9de";
$icon-thermometer-minus-outline: "\e9df";
$icon-thermometer-outline: "\e9e0";
$icon-thermometer-plus-outline: "\e9e1";
$icon-toggle-left-outline: "\e9e2";
$icon-toggle-right-outline: "\e9e3";
$icon-trash-2-outline: "\e9e4";
$icon-trash-outline: "\e9e5";
$icon-trending-down-outline: "\e9e6";
$icon-trending-up-outline: "\e9e7";
$icon-tv-outline: "\e9e8";
$icon-twitter-outline: "\e9e9";
$icon-umbrella-outline: "\e9ea";
$icon-undo-outline: "\e9eb";
$icon-unlock-outline: "\e9ec";
$icon-upload-outline: "\e9ed";
$icon-video-off-outline: "\e9ee";
$icon-video-outline: "\e9ef";
$icon-volume-down-outline: "\e9f0";
$icon-volume-mute-outline: "\e9f1";
$icon-volume-off-outline: "\e9f2";
$icon-volume-up-outline: "\e9f3";
$icon-wifi-off-outline: "\e9f4";
$icon-wifi-outline: "\e9f5";

