.review-progress {
    .page-main__header {
        padding-top   : 1rem;
        padding-bottom: 1rem;

        @include media-breakpoint-up(lg) {
            padding-top   : 2rem;
            padding-bottom: 2rem;
        }
    }

    .table-responsive {
        .table {
            margin-bottom: 0;
        }
        tr {
            th:first-child {
                min-width: 280px;
                text-align:left;
            }
            th {
                vertical-align: middle;
                font-weight: bold;
            }
            td {
                text-align    : center;
                vertical-align: middle;
                font-weight: bold;
            }
        }

        .avatar {
            margin-right: 0.5rem;
        }
        .keys__item {
            padding:0;
        }
    }
    
}