.error-wrapper {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 92vh;
    background     : url(../../images/background-welcome-page-50.png) no-repeat center center;
    background-size: 538px auto;

    .error-message {
        position  : relative;
        text-align: center;

        &__code {
            h1 {
                font-size  : 5rem;
                font-weight: $font-weight-bold;
                color      : $gray-50;
                text-shadow: 0px 5px 15px rgba($black, 0.15), 0px 2px 0px $border-color, 0px 4px 2px rgba($black, 0.2);
            }
        }


        &__extras {
            &-c {
                position        : absolute;
                bottom          : 0;
                left            : 50%;
                transform-origin: center;
                transform       : translateX(-50%);
                width           : 1.5rem;
                height          : 1.5rem;
                border          : 3px solid #FDCE5B;
                border-radius   : 1.5rem;

            }

            &-s {
                position        : absolute;
                bottom          : 15%;
                left            : 50%;
                transform-origin: center;
                transform       : translateX(-50%);
                width           : 1.5rem;
                height          : 1.5rem;
                border          : 3px solid #019BFF;
                border-radius   : 3px;
                animation       : emsAnim 1s infinite alternate both;

            }
        }

        &__pivot {
            position        : absolute;
            top             : calc(50% - 30px);
            left            : 50%;
            width           : 30px;
            height          : 80%;
            transform-origin: center top;
            transform       : translateX(-50%);

            z-index: -1;

            &:nth-child(1) {
                animation: emcAnim 17s infinite alternate both;

                &>* {
                    border-color: #FDCE5B;
                }
            }

            &:nth-child(2) {
                animation: emcAnim 20s infinite 1s both;

                &>* {
                    border-color: $success;
                }
            }

            &:nth-child(3) {
                animation: emcAnim 22s infinite 0.5s reverse both;

                &>* {
                    border-color: #FDCE5B;
                }
            }

            &:nth-child(4) {
                animation: emcAnim 16s infinite 0.2s both;

                &>* {
                    border-color: #019BFF;
                }
            }

            &:nth-child(5) {
                animation: emcAnim 25s infinite 1.2s both;

                &>* {
                    border-color: $primary;
                }
            }

            &:nth-child(6) {
                animation: emcAnim 19s infinite both;

                &>* {
                    border-color: #FF3737;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &__code {
                h1 {
                    font-size: 7rem;
                }
            }

            &__pivot {
                height: 120%;
            }
        }
    }
}

@keyframes emcAnim {
    0% {
        transform: rotate(360deg);
    }

    50% {
        transform: rotate(-180deg) scale(1.25);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes emsAnim {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(72deg);
    }
}