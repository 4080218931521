.page-wrapper {
    position: relative;
    display :flex; 
}

.page-main {
    position  : relative;
    flex      : auto;
    padding   : 1rem 0;    

    &__header {

        padding-bottom: 1rem;
        width: 100%;

        &-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-top: 1rem;
        }
    }
    &__title {
        margin-bottom: 1rem
    }
    &__controls {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        margin : 0 0 1rem 0;
        padding: 0;
        
        li {
            list-style  : none;
            display     : inline-flex;
            margin-right: 0.625rem;

            &:last-child {
                margin-right: 0;
            }
        }

    }
    &.has-offset {
        margin-right: 20rem;
    }

    @include media-breakpoint-up(lg) {
        padding: 2rem 0;

        &__header {
            padding-bottom: 2rem;

            &-buttons {
                justify-content: flex-end;
            }
        }
        &__title {
            margin-bottom: 0;
        }
        &__controls {
            justify-content: flex-end;
            flex-wrap: nowrap;
            margin-bottom: 0;
        }
        &.has-offset {
            margin-right: 30rem;
        }
    }


}

.page-aside {
    display: none;
    position    : absolute;
    right       : 0;
    top         : 0;
    bottom      : 0;
    min-height  : 100vh;
    flex        : 0 1 20rem;
    min-width       : 20rem;
    overflow: auto;
    transition  : margin 400ms ease-in-out;
    z-index     : 99;
    // margin-right: -20rem;

    &.is-visible {
        // margin-right: 0rem;
        display: flex;
        width: 20rem;
    }
    &.has-resource-panel {
        background: $white;
        border-left: 1px solid $border-color;
    }


    .default-row__title {
        font-size: 0.875rem !important;
        max-width: 220px;
    }

    .default-row__activity-type {
        margin-left: 1.75rem;
    }

    @include media-breakpoint-up(xl) {
        // position    : relative;
        // top         : 0;
        // left        : 0;
        // width       : 30rem;
        // margin-right: -30rem;

        @supports (position: sticky) {
            position: sticky;
            top:0;
        }
        display: none;
        
        height: calc(100vh - 4.125rem);
        // height: 100vh;
        flex: 0 1 30rem;
        min-width: 30rem;

        &.is-visible {
            display: flex;
        }

    }
}