.accordion-main-category {
    margin    : 0;
    padding   : 0;
    background: $white;

    //li
    &__item {

        padding      : 0;
        list-style   : none;
        width        : 100%;
        border-bottom: 1px solid $border-color;

        a {
            &:hover {
                text-decoration: none;
            }
        }

        &-title {

            position       : relative;
            display        : flex;
            justify-content: flex-start;
            align-items    : center;
            width          : auto;
            margin-left    : auto;
            
            padding        : 0.625rem;
            color          : $gray-700;
            font-weight    : bold;
            border-top     : 1px solid $border-color;

            &:first-child {
                border-top: 0;
            }

            &:hover {
                color: $primary;
            }

            &>.thumbnail {
                margin-right: 0.625rem;
            }


        }
    }

    &__trigger {
        margin-left: auto;
        width: 40px;
        height: 40px;

        &::after {
            content         : $icon-chevron-up-outline;
            font-family     : $edusmart-font-family;
            position        : absolute;
            top             : 50%;
            right           : 0;
            padding         : 1rem;
            margin          : 0;
            color           : $gray-300;
            font-size       : 1.333rem;
            transform-origin: left center;
            transform       : translateY(-50%);
        }

        &:not(.collapsed) {
            &::after {
                content: $icon-chevron-down-outline;
            }
        }
    }

    &__list {
        position       : relative;
        margin         : 0;
        background     : $gray-50 url(../../images/triangle-pattern.png) repeat-x left top;
        background-size: 12px 12px;
        width          : 100%;
    }

    &__categories_item {
        display        : flex;
        justify-content: flex-start;
        align-items    : center;
        flex-wrap      : wrap;

        background   : $white;
        border       : 1px solid $border-color;
        box-shadow   : 0 2px 0 0 $border-color;
        border-radius: $border-radius;

        margin-bottom: 0.625rem;
        padding      : 1rem 0.625rem !important;
        width        : 100%;



        a {
            font-size  : 0.825rem;
            color      : $gray-700;
            font-weight: bold;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-list {
            position: relative;
            width   : 100%;
            margin  : 0;
            padding : 0.625rem;

            li {
                border-bottom: 1px solid $border-color;

                &:last-child {
                    border-bottom: 0;
                }
            }

        }
    }

    //  &__item-child {
    //      margin : 0;
    //      padding: 0;

    //      li {
    //          position        : relative;
    //          // padding      : 0 0 0 2rem;
    //          padding         : 0;
    //          border-bottom   : 1px solid $border-color;

    //          &::before {
    //              content         : $icon-chevron-right-outline;
    //              font-family     : $edusmart-font-family;
    //              color           : $gray-300;
    //              position        : absolute;
    //              top             : 50%;
    //              left            : 0.6667rem;
    //              padding         : 0;
    //              margin          : 0;
    //              font-size       : 1.333rem;
    //              transform-origin: left center;
    //              transform       : translate(-3px, -50%);
    //              transition      : $transition-base;
    //          }

    //          &:hover {
    //              &::before {
    //                  transform: translate(0px, -50%);
    //                  color    : $primary;
    //              }
    //          }

    //          a {
    //              padding    : 0.625rem 0 0.625rem 0;
    //              display    : block;
    //              color      : $gray-500;
    //              font-weight: 500;

    //              &.is-active,
    //              &:hover {
    //                  color: $primary;
    //              }
    //          }

    //      }

    //      //li

    //      &.is-active {
    //          background: rgba($primary, 0.05);

    //          li {
    //              border-bottom: 1px solid rgba($primary, 0.15);
    //          }
    //      }
    //  }
}

.accordion-sub-category {
    padding: 1.25rem 0.625rem 0.625rem 0.625rem;

    &__item {

        background   : $white;
        border       : 1px solid $border-color;
        box-shadow   : 0 2px 0 0 $border-color;
        border-radius: $border-radius;
        margin-bottom: 0.625rem;



        &-header {
            position       : relative;
            display        : flex;
            justify-content: flex-start;
            align-items    : center;
            flex-wrap      : wrap;
            width          : 100%;
            border-bottom  : 1px solid $border-color;

            a {
                position   : relative;
                flex       : 1;
                color      : $gray-700;
                padding    : 0.875rem  0.625rem 0.875rem 1rem;
                font-weight: bold;

                // &::after {
                //     content         : $icon-chevron-up-outline;
                //     font-family     : $edusmart-font-family;
                //     position        : absolute;
                //     top             : 50%;
                //     right           : 0;
                //     padding         : 1rem;
                //     margin          : 0;
                //     color           : $gray-300;
                //     font-size       : 1.333rem;
                //     transform-origin: left center;
                //     transform       : translateY(-50%);
                // }

                // &:not(.collapsed) {  
                //     color: $primary;

                //     &::after {
                //         content: $icon-chevron-down-outline;
                //     }
                // }

            }

            &.is-active {
                & {
                    background   : $white;
                    border-radius: $border-radius;
                    background   : $primary;
                    border-radius: $border-radius $border-radius 0 0;
                    border-bottom: 1px solid $primary;

                    a,
                    .accordion-sub-category__move {
                        color: $white;
                    }

                    a::after {
                        content: $icon-chevron-down-outline;
                        color  : $white;

                    }
                }
            }
        }

        &.has-test {
            padding   : 0.625rem;
            border    : 1px solid darken($light-green, 20%);
            box-shadow: 0 2px 0 0 darken($light-green, 20%);
            background: $light-green;
            transition: 0.3s;

            a {
                font-weight: bold;
                color      : $primary;
            }

            img {
                max-height  : 2rem;
                margin-right: 0.625rem;
            }

            &:hover {
                background: $white;
                border    : 1px solid $border-color;
                box-shadow: 0 2px 0 0 $border-color;
            }
        }
    }

    &__move,
    &__list-move {
        flex         : 0 0 auto;
        position     : relative;
        top          : 50%;
        left         : 0;
        line-height  : 1;
        color        : $gray-300;
        font-size    : 1.25rem;
        width        : auto;
        border-radius: 0;
        border       : none;
        background   : transparent;
        text-align   : left;
        padding      : 0.78125rem;
        margin-bottom: 0;

        &:hover {
            color: $primary;
        }
    }

    &__list {
        flex   : 0 1 100%;
        width  : 100%;
        margin : 0;
        padding: 0;

        li {
            display        : flex;
            justify-content: flex-start;
            align-items    : center;
            border-bottom  : 1px solid $border-color;
            border-left    : 2px solid $primary;
            transition     : 0.2s;

            &:last-child {
                border-bottom: 0;
            }

            a {
                font-weight: 500;
            }

            &:hover {

                background-color: $light-green;

                a,
                i {
                    color: $primary;

                }

                a {
                    width: 100%;
                }
            }

            &.has-test,
            &.is-editable {
                background-color: $gray-100;
                padding         : 0.78125rem;
                border-radius   : 0 0 $border-radius $border-radius;
                border-left     : 1px solid $border-color;

                a {
                    font-weight: bold;
                    color      : $gray-700;
                }

                i {
                    position : relative;
                    top      : 3px;
                    font-size: 1.25rem;
                }

                img {
                    max-height  : 2rem;
                    margin-right: 0.625rem;
                }

                &:hover {
                    a {
                        color: $primary;
                    }
                }
            }
        }


    }
}