@mixin default-inset($val, $darkenVal: 4% ) {
    box-shadow: inset 0 #{$val} 0 0 darken($border-color, $darkenVal); 
}
@mixin default-dark-inset($val) {
    box-shadow: inset 0 #{$val} 2px 0 rgba(0,0,0,.2);
}
@mixin default-small-shadow() {
    box-shadow: 0 2px 4px 0px rgba(0,0,0,0.25);
}
@mixin default-large-shadow() {
    box-shadow: 0 7px 15px 0px rgba(0,0,0,0.15);
}

