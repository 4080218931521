.default-container {
    $dc-parent   : &;
    position: relative;
    background   : $white;
    border       : 1px solid $border-color;
    box-shadow   : 0 2px 0 0 $border-color;
    border-radius: 8px;
    padding      : 0;
    margin       : 0;
    width: 100%;

    img {
        max-width: 100%;
    }

    &.has-tabs {
        border-top-left-radius: 0px;
        border-top            : 0;
    }

    &.has-tests {
        border-top-right-radius: 0px;
        border-top             : 0;


    }

    &.has-outer {
        border-bottom-right-radius: 0px;
    }



    &__content {
        position: relative;
        padding   : 0.75rem;
        // padding: 1rem;

        @include media-breakpoint-up(lg) {
            // padding: 2rem;
            padding   : 1.25rem;
        }

        &.has-rows {
            justify-content: flex-start;
            align-items    : flex-start;
            flex-flow      : row wrap;
        }
    }

    &__header {
   
        padding: 1.25rem 0.75rem 0.75rem 0.75rem;

        a {

            &:hover {
                 text-decoration: none;
            }
        }

        h3,
        h4 {
            margin-bottom: 0;
        }

        &.is-dark {
            background: #202950;
        }

        &.is-special {
            display        : flex;
            flex-wrap      : wrap;
            justify-content: flex-start;
            align-items    : center;
            width          : 100%;
            background     : $gray-50 url(../../images/triangle-pattern-down.png) repeat-x left bottom;
            background-size: 12px 12px;
            padding        : 1.5rem 1.5rem 2rem 1.5rem;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            margin         : 0;

            .avatar__name {
                display        : flex;
                justify-content: flex-start;
                align-items    : flex-start;
                flex-wrap      : wrap;
                margin-left    : 1rem;

                h4,
                .h4,
                .subtitle {
                    flex: 1 0 100%;
                }
            }

            img.test-type-icon {
                height      : 3rem;
                margin-right: 1.25rem;
            }
        }

        &-title {
            margin-bottom: 1rem;
        }
        &-features {

            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 0;
        }
        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }
        @include media-breakpoint-up(lg) {

            // padding: 1.25rem 1.25rem 0 1.25rem;

            // &-title {
            //     margin-bottom: 0;
            // }
            // &-features {
            //     margin-left: auto;
            // }
            justify-content: space-between;
            padding        : 1.25rem;
        }
    }

    &__footer {
        display        : flex;
        justify-content: flex-end;
        align-items    : center;
        padding        : 1rem;
        // border-top  : 1px solid $border-color;
        border-radius  : 0 0 5px 5px;

        &.has-background {
            justify-content: space-between;
            margin-left    : 0;
            margin-right   : 0;
            background     : $gray-100 url("../../images/triangle-pattern.png") repeat-x left top;
            background-size: auto 12px;
        }

        &.is-centered {
            justify-content: center;
        }


        @include media-breakpoint-up(lg) {
            // padding        : 2rem;
            // padding           : 1.25rem;
            // padding-top    : 0;
            margin-right      : auto;

        }

        &.has-rows {
            justify-content: flex-start;
            align-items    : flex-start;
            flex-flow      : row wrap;
        }
    }

    &.has-columns {

        display        : flex;
        justify-content: flex-start;
        align-items    : center;
        flex-wrap      : wrap;
        padding        : 1rem;

        #{$dc-parent}__header {
            flex           : 1 0 auto;
            justify-content: center;
            text-align     : center;
        }

        #{$dc-parent}__content {
            flex           : 1 1 auto;
            justify-content: center;
            text-align     : center;
        }

        #{$dc-parent}__footer {
            flex           : 1 0 auto;
            justify-content: center;
        }

        @include media-breakpoint-up(lg) {
            display        : flex;
            justify-content: flex-start;
            align-items    : center;
            flex-wrap      : nowrap;

            #{$dc-parent}__header {
                flex           : 0 0 auto;
                justify-content: flex-start;
                text-align     : left;
            }

            #{$dc-parent}__content {
                flex           : 1 1 auto;
                justify-content: flex-start;
                text-align     : left;
            }
        }
    }

    // &--list-view {}
    &--grid-view {

        display        : flex;
        justify-content: space-between;
        flex-wrap      : wrap;


        .default-row {
            display  : flex;
            flex-wrap: wrap;
            max-width: 100%;
            flex     : 1 0 auto;

            &__content {
                flex-wrap      : wrap;
                justify-content: center;
            }

            &__thumbnail {
                margin-bottom: 1rem !important;
            }

            &__introduction {
                margin-bottom: 1rem !important;
            }

            &__introduction,
            &__features {
                width     : 100%;
                text-align: center !important;
            }

            &__footer {
                .workspace__attachments-list {
                    .row {
                        [class^="col-"] {
                            flex-basis: 0;
                            flex-grow : 1;
                            max-width : 100%;
                        }
                    }
                }

                @include media-breakpoint-up(md) {
                    display: none !important;
                }

            }

            .progress-wrapper {
                span {
                    position: relative !important;
                    top     : 0 !important;
                }
            }
        }

        @include media-breakpoint-up(md) {
            .default-row {
                max-width: 49%;
            }
        }

        @include media-breakpoint-up(lg) {
            .default-row {
                max-width: 32%;
            }
        }

    }
}

.default-container__outer {
    position       : relative;
    display        : flex;
    justify-content: flex-end;
    align-items    : flex-start;

    ul {
        margin : 0;
        padding: 0;

        li {
            display        : inline-flex;
            background     : $gray-200;
            list-style     : none;
            list-style-type: none;
            border-radius  : 0 0 0.5rem 0.5rem;
            margin-left    : 0.25rem;
            transition     : all 200ms ease-in-out;

            &:hover {
                background-color: darken($gray-200, 4%);
            }

            a {
                display        : inline-flex;
                align-items    : center;
                padding        : 0.525rem 1rem;
                color          : $dark;
                font-size      : 0.8125rem;
                text-decoration: none;
                font-weight    : bold;

                i {
                    font-size  : 1.25rem;
                    margin-left: 0.5rem;
                }
            }
        }
    }
}