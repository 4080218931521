.thumbnail {
    $thumb-parent: &;
    position     : relative;
    // margin       : 0 auto;
    width        : 102px;
    height       : 74px;
    background   : $white;
    border-radius: $border-radius;
    overflow     : hidden;
    flex         : 0 0 auto;
    img {
        display  : inline-block;
        max-width: 100%;
        z-index  : 0;
    }
    &.is-small {
        width        : 51px;
        height       : 37px;
        border-radius: $border-radius/2;

        #{$thumb-parent}__resource-type {
            transform: scale(0.5) translate(-18px, 18px);
        }
    }

    &.is-medium {
        width        : 68px;
        height       : 49px;
        border-radius: $border-radius/1.5;
    }


    &__resource-type {
        position: absolute;
        left    : 0.375rem;
        bottom  : 0.3175rem;
        z-index : 3;
    }

    &__resource-type-alt {
        position: absolute;
        bottom: 0;
        left:0;
        background: $white;
        border-radius: 0 $border-radius 0 0;
        width: 2rem;
        height: 2rem;
        img {
            position: relative;
            top:0.25rem;
            left:0.32rem;
            max-width: 1.25rem !important;
        }
    }




}