.avatar-group {
    position    : relative;
    display     : inline-flex;
    padding-left: 1.5rem;

    .avatar {

        margin-left: -1.25rem;
        border: 3px solid $white;
        border-radius: 100px;

    }

}

.avatar {
    $avatar_parent: &;
    position      : relative;
    display       : inline-flex; //fix for col-* class
    align-items: center;
    

    &.is-anonymous {
        #{$avatar_parent}__image {

            background: $gray-100 url("../../images/anonymous-icon.svg") no-repeat center center;

            img {
                visibility: hidden;
                opacity   : 0;
            }
        }
    }

    &.is-placeholder {
        #{$avatar_parent}__image {
            padding-top     : 0.95rem;
            padding-left    : 1px;
            background-color: $blue;
            text-align      : center;
            font-size       : 0.75rem;
            font-weight     : bold;
            color           : $white;
            box-shadow      : none;
        }
    }
    &.is-placeholder-small {
        #{$avatar_parent}__image {
            padding-top     : 0.5rem;
            padding-left    : 1px;
            background-color: $blue;
            text-align      : center;
            font-size       : 0.75rem;
            font-weight     : bold;
            color           : $white;
            box-shadow      : none;
        }
    }

    &.is-small {
        #{$avatar_parent}__image {
            width        : 2.25rem;
            height       : 2.25rem;
            border-radius: 2.25rem;

        }
    }

    &.is-large {
        #{$avatar_parent}__image {
            width           : 4rem;
            height          : 4rem;
            border-radius   : 4rem;
            // padding-top  : 0.95rem;
            font-size       : 1.5rem;
        }

        #{$avatar_parent}__status {
            bottom   : 3px;
            right    : 3px;
            transform: scale(1.25);
        }
    }
    &.is-subject {
    
        #{$avatar_parent}__image {
            border-radius: 0;
            box-shadow: none;
            text-align:center;
            
            img {
                max-width: 100%;
                height       : 2.25rem;
            }

        }
        
    }

    &__name {
        display        : inline-flex;
        flex-flow      : column wrap;
        justify-content: center;
        align-items    : flex-start;
        color          : $gray-700;
        margin-left    : 0.625rem;
        max-width: 75%;

        h4,h5, .h4, .h5 {
            margin: 0;
        }
        &.is-sharing {
            span {
                color: $gray-400;
                i {
                    @extend %default-icon-style;
                    font-size: 0.9375rem;
                }
            }
            font-weight: bold;
            font-size: 0.875rem;
        }
    }

    &__image {
        width        : 3rem;
        height       : 3rem;
        border-radius: 30rem;
        overflow     : hidden;
        @include default-inset(-3px);
        

        img {
            max-width: 100%;
        }
    }

    &__status {
        position     : absolute;
        bottom       : 0;
        right        : 0;
        width        : 0.6875rem;
        height       : 0.6875rem;
        border-radius: 0.6875rem;
        background   : $success;
        border       : 2px solid $white;
        @include default-dark-inset(-2px);

        &.is-inactive {
            background: $danger;
        }

    }

}