.default-timeline {
    display  : flex;
    flex-flow: row wrap;

    &__line {
        position: relative;
        flex    : 0 0 5rem;

        display        : flex;
        flex-flow      : column wrap;
        justify-content: flex-start;
        align-items    : center;
        margin-right   : 1rem;

        &::before {
            content         : "";
            position        : absolute;
            left            : 2.48rem;
            top             : 0;
            bottom:0;
            transform-origin: center;
            transform       : translateX(-50%);
            width           : 3px;
            background: rgb(226,227,232);
            background: linear-gradient(180deg, rgba(226,227,232,1) 0%, rgba(255,255,255,1) 87%);
        }
    }

    &__content {
        flex: 1;
        margin-bottom: 2rem;
    }

    &__timestamp {
        position     : relative;
        display      : flex;
        align-items  : center;
        width        : 100%;
        margin-bottom: 1rem;

        &-date {
            position    : relative;
            margin-right: 1rem;
            padding     : 0.75rem;
            @include default-inset(-2px);
            border-radius: 100px;
            background   : $gray-50;
            border       : 1px solid $gray-100;

            &::before {
                content         : "";
                position        : absolute;
                left            : 2.40625rem;
                top             : 100%;
                height          : 2rem;
                bottom          : 0;
                transform-origin: center;
                transform       : translateX(-50%);
                width           : 3px;
                background-color: $border-color;
            }
        }

    }


}