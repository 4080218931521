.notification {
    $notification-parent: &;

    display      : flex;
    flex-wrap    : wrap;
    max-width    : 23rem;
    background   : $white;
    border       : 1px solid $border-color;
    border-radius: $border-radius;
    transition   : $transition-base;
    @include default-large-shadow();
    margin-bottom: 1rem; // comment this if spacing is not required

    &.has-text-only {
        #{$notification-parent}__aside {
            padding: 0;
        }
    }

    &__aside {
        align-self: center;
        padding   : 1rem 0 0.9rem 1rem;
        text-align: center;

        img {
            max-height: 2.5rem;
        }

        i {
            font-size  : 1.75rem;
            color      : $primary;
            line-height: 1;
            padding    : 0;
            margin     : 0;
        }

    }

    &__content {
        flex      : 1;
        padding   : 1rem 0 1rem 1rem;
        align-self: center;

        h6 {
            margin-bottom: 0;
        }

        p {
            font-size     : 0.875rem;
            padding-bottom: 0;
            margin-bottom : 0;
        }
    }

    &__footer {
        padding   : 0.625rem 1rem;
        flex      : 1 0 100%;
        border-top: 1px solid $border-color;

        display        : flex;
        justify-content: space-evenly;
        align-items    : center;
        align-content  : center;

        a {
            &:hover {
                text-decoration: none;
            }
        }

    }

    &__close {
        flex        : 0 0 auto;
        margin-left : auto;
        margin-right: 1rem;
        padding-left: 1rem;
        align-self  : center;

        a {

            padding: 0.25rem;

            i {

                font-size     : 1.25rem;
                color         : $gray-400;
                padding-bottom: 0;
                line-height   : 1.56;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}