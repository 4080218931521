.icon-list {
    display         : inline-flex;
    flex-flow       : column wrap;
    justify-content : flex-start;
    text-align      : left;
    margin          : 0;
    padding         : 0;
    font-weight     : bold;
    color           : $gray-700;
    transform-origin: center center;
    transition      : $transition-base;
    visibility      : visible;

    &.is-horizontal {
        flex-flow      : row wrap;
        justify-content: flex-start;
        align-content  : flex-start;

    }

    &.is-hidden {
        display         : none;
        visibility      : hidden;
        transform-origin: center center;
        transform       : scale(0);
    }

    li {
        display        : flex;
        justify-content: flex-start;
        align-items    : center;
        list-style     : none;
        font-family    : $font-family-base;
        line-height    : 1;
        margin-right   : 1rem;

        &:last-child {
            margin-right: 0;
        }

        i {
            font-size: 1.5rem;
            color    : $gray-300;
            padding  : 0 0.5rem 0 0;
            margin   : 0;
        }
    }


}