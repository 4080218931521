.inline-edit {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    border-radius: $border-radius-xl;
    background   : $white;
    border       : 1px solid $border-color;
    box-shadow   : 0 1px 0 0 $border-color;
    margin-bottom: 1rem;

    &__field {
        flex: 1;
        margin-right: 12px;

        input {
            width:100%;
            height: 100%;
            background: transparent;
            box-shadow: none;
            border:0;
            outline:0;
            font-weight: bold;
        }
    }
    &__features {
        flex: 0 0 auto;
        margin: 3px 3px 3px 0;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        min-width: 180px;
        display: inline-flex;
    }
}