.main-category-navbar {
  & > .nav-pills {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      border-top: 1px solid $border-color;
    }
    &:last-child {
      border-bottom: 1px solid $border-color;
    }
  }

  //bok
  .main-content-navbar__lvl0 {
    display: flex;
    align-items: center;
    padding: 0.625rem;
    color: $gray-800;
    font-weight: bold;
    border-top: 1px solid $border-color;

    &:first-child {
      border-top: 0;
    }

    .nav-link {
      padding: 0;
      color: $gray-800;

      &.active {
        background: $white;
        color: $primary;
      }

      & > .thumbnail {
        margin-right: 0.625rem;
      }
    }
  }

  //reporting
  .main-content-navbar__lvl1 {
    position: relative;
    margin: 0;
    background: $gray-50 url(../../images/triangle-pattern.png) repeat-x left
      top;
    background-size: 12px 12px;
    width: 100%;
    padding: 0 0.625rem;

    & > .nav-pills {
      position: relative;
      display: flex;
      flex-direction: row;
      background: $white;
      border: 1px solid $border-color;
      box-shadow: 0 2px 0 0 $border-color;
      border-radius: $border-radius;
      margin-bottom: 0.625rem;
      width: 100%;

      &:first-child {
        margin-top: 1.25rem;
      }
    }

    .nav-link {
      position: relative;
      color: $gray-800;
      font-weight: bold;
      padding: 1rem 3rem 1rem 2.5rem;

      width: 100%;
      border-radius: 0;

      i {
        color: $gray-300;
        position: absolute;
        top: 50%;
        left: 0.625rem;
        transform-origin: center;
        transform: translateY(-50%);
        @extend %default-icon-style;
        transition: 0.2s;
      }

      &.active {
        color: $primary;
        background: rgba(255, 101, 0, 0.1);
        border-radius: $border-radius $border-radius 0 0;
        border-bottom: 1px solid darken($light-green, 7%);

        i {
          color: $primary;
        }
      }
    }

    .collapse-toggler {
      position: absolute;
      right: 0rem;
      top: 0rem;

      height: 3.5rem;

      &::after {
        padding-right: 0.625rem;
        padding-left: 0.375rem;
      }
    }
  }

  //unit
  .main-content-navbar__lvl2 {
    position: relative;
    width: 100%;

    .nav-pills {
      .nav-link {
        padding: 1rem;
        width: 100%;
        border-radius: 0;
        border-top: 0;
        font-weight: normal;
        border: 1px solid transparent;
        color: $gray-700;

        &:hover {
          background-color: lighten($gray-100, 7%);
          color: $gray-800;
        }

        &.active {
          color: $primary;
          border-radius: 0;
        }

        &:first-child {
          border-top: 1px solid $border-color;
        }

        &:last-child {
          border-radius: 0 0 $border-radius $border-radius;
        }
      }
    }
  }
}

// .main-category-navbar {

//     position     : relative;
//     display      : flex;
//     flex-flow    : column wrap;
//     width        : 100%;
//     // border    : 1px solid $border-color;
//     margin       : 0;
//     padding      : 0;
//     background   : $white;

//     //bok - parent nav-pill
//     & >.nav-pills {

//         position: relative;
//         display : flex;
//         width   : 100%;

//         & >.nav-link {

//             // display   : flex;
//             // background: #000;

//             padding-top  : 0.625rem;
//             border-radius: 0;
//             background   : $gray-50;
//             border-top   :1px solid $border-color;
//             font-weight  : bold;
//             .thumbnail {
//                 margin-right: 0.625rem;
//             }

//             &.active {
//                 color           : $primary;
//                 font-weight     : bold;
//                 background      : $light-green;
//                 // border-top   : 1px solid darken($light-green, 7%);
//                 // border-bottom: 1px solid darken($light-green, 7%);
//             }

//         }

//         //categories
//         &>.nav-pills {

//             border-radius: 0;
//             margin       : 0;
//             width        : 100%;

//             &>.nav-link {

//                 position     : relative;
//                 margin       : 0;
//                 border-radius: 0;
//                 padding      : 1rem 1rem 1rem 2.5rem;
//                 border-bottom: 1px solid $border-color;
//                 border-top   : 1px solid $border-color;
//                 border-left  : 1px solid transparent;
//                 box-shadow   : inset -3px 0 0 0 transparent;
//                 font-weight  : bold;
//                 color        : $gray-700;

//                 i {
//                     color           : $gray-300;
//                     position        : absolute;
//                     top             : 50%;
//                     left            : 0.625rem;
//                     transform-origin: center;
//                     transform       : translateY(-50%);
//                     @extend %default-icon-style;
//                     transition: 0.2s;
//                 }

//                 &:hover {
//                     color: $primary;
//                 }

//                 &.active {
//                     color      : $primary;
//                     background : transparent;
//                     border-left: 1px solid $primary;
//                     box-shadow : inset 3px 0 0 0 $primary;

//                     i {
//                         color    : $primary;
//                         transform: translateY(-50%) scale(1.2);
//                     }
//                 }
//             }

//             //units
//             &>.nav-pills {
//                 border-radius: 0;
//                 margin       : 0;

//                 &>.nav-link {

//                     position     : relative;
//                     margin       : 0;
//                     border-radius: 0;
//                     padding      : 0.8rem 1rem 0.8rem 2.5rem;
//                     color        : $gray-500;
//                     border-top   : 1px solid transparent;
//                     border-bottom: 1px solid transparent;
//                     border-left  : 1px solid transparent;

//                     i {
//                         color           : $gray-300;
//                         position        : absolute;
//                         top             : 50%;
//                         left            : 0.625rem;
//                         transform-origin: center;
//                         transform       : translateY(-50%);
//                         @extend %default-icon-style;
//                         transition: 0.2s;
//                     }

//                     &:hover {
//                         color      : $primary;
//                         border-left: 1px solid $primary;
//                     }

//                     &.active {
//                         background   : $light-green;
//                         color        : $primary;
//                         border-top   : 1px solid darken($light-green, 7%);
//                         border-bottom: 1px solid darken($light-green, 7%);

//                         i {
//                             color: $primary;
//                         }

//                         &:first-child {
//                             border-top: 1px solid transparent;
//                         }

//                         &:last-child {
//                             border-bottom: 1px solid $border-color;
//                         }
//                     }

//                 }
//             }
//         }
//     }

// }
