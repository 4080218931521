.main-content-list {

    position: relative;


    .default-container__content {
        padding-left : 0;
        padding-right: 0;
    }

    &__lvl-0 {
        position: relative;

    }

    &__lvl-1 {
        $lvl1-parent: &;
        margin-bottom: 1rem;

        &-heading {
            @supports(position:sticky) {
                position: sticky;
                top : 0;
            }

            // position     : relative;
            padding         : 0.8rem 1rem 0.625rem 3rem;

            background   : $gray-50;
            border-top   : 1px solid $border-color;
            border-bottom: 1px solid $border-color;

            i {
                @extend %default-icon-style;
                position        : absolute;
                top             : 53%;
                left            : 1rem;
                transform-origin: center;
                transform       : translateY(-50%);

            }
        }
    }

    &__lvl-2 {
        padding: 0 1rem;


        h6,
        .h6 {
            position: relative;
            padding : 0.625rem 1rem 0.8rem 2rem;

            color: $gray-500;

            i {
                @extend %default-icon-style;
                position        : absolute;
                top             : 45%;
                left            : 0;
                transform-origin: center;
                transform       : translateY(-50%);

            }
        }
    }


}