.glossary-card-list {
  [class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    & + label {
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    &:checked + label {
      .glossary-card {
        &__wrapper {
          border-color: $success;
        }

        &__name {
          color: $success;
        }
      }
    }
  }
}

.glossary-paragraph-list {
  input[type="radio"],
  input[type="checkbox"] {
    & + label {
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    &:checked + label {
      .glossary-paragraph {
        border-color: $success;
      }
    }
  }
}

.glossary-paragraph {
  height: 85px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  transition: transform 500ms ease-in-out;
  background: $white;
  border: 2px solid #9a9a9a;
  box-shadow: 0 5px 16px -4px rgba(39, 38, 38, 0.5);
  border-radius: $border-radius;

  &--correct {
    overflow: hidden;
    border-color: $success;

    &::after {
      content: $icon-checkmark-outline;
      font-family: $edusmart-font-family;
      position: absolute;
      top: 2px;
      right: 5px;
      color: $white;
      font-size: 1rem;
      transform: scale(1);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 0;
      height: 0;
      transform: translate(0px, 0px) scale(1);
      border-style: solid;
      border-width: 0 42px 42px 0;
      border-color: transparent $success transparent transparent;
    }
  }

  &__description {
    color: $body-color;
    font-weight: normal;
  }

  &__controls {
    margin-left: 1rem;
  }
}

.glossary-card {
  width: 130px;
  height: 210px;
  $qc-parent: &;
  background-color: transparent;
  perspective: 1200px;
  margin-bottom: 1rem;

  // completed stated revealed (filped)
  &--completed {
    #{$qc-parent}__wrapper {
      transform: rotateY(180deg);
    }

    #{$qc-parent}__front {
      transition-delay: 175ms;
      transition-timing-function: ease-in-out;
      visibility: hidden;
    }
  }

  &--correct {
    #{$qc-parent}__wrapper {
      border-color: $success;
      box-shadow: inset 0px 0px 0px 1px $success,
        0 5px 16px -4px rgba(39, 38, 38, 0.5);
    }

    #{$qc-parent}__name {
      color: $success;
    }

    #{$qc-parent}__front {
      overflow: hidden;

      &::after {
        content: $icon-checkmark-outline;
        font-family: $edusmart-font-family;
        position: absolute;
        top: 2px;
        right: 5px;
        color: $white;
        font-size: 1rem;
        transform: scale(1);
      }

      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        width: 0;
        height: 0;
        transform: translate(0px, 0px) scale(1);
        border-style: solid;
        border-width: 0 42px 42px 0;
        border-color: transparent $success transparent transparent;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;

    .btn {
      background-color: $white;
    }
  }

  &__content {
    padding-bottom: 2rem;
  }

  &__footer {
    a {
      font-weight: bold;
      color: $headings-color;
      line-height: 1.325;
      text-decoration: none;

      &:hover {
        color: $primary;
      }
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;

    transition: transform 500ms ease-in-out;
    border: 2px solid #9a9a9a;
    box-shadow: 0 5px 16px -4px rgba(39, 38, 38, 0.5);
    border-radius: $border-radius;
  }

  &__front,
  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: $border-radius;
  }

  &__front {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    background-color: $white;
    padding: 5px;
  }

  &__back {
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
    padding-top: 0.75rem;
    padding-bottom: 1.735rem;
    background: $white url(../../images/question-card-bg.svg) no-repeat bottom
      64px center;
    transform: rotateY(180deg);
  }

  &__image {
    img {
      border-radius: $border-radius;
    }
  }

  &__name {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: $headings-color;
    transition: $transition-base;
    line-height: 1.325;
  }

  a#{$qc-parent}__name {
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }

  @media only screen and (min-width: 321px) {
    width: 180px;
    height: 300px;
    font-size: 1rem;
  }
  @include media-breakpoint-up(lg) {
    width: 190px;
    height: 328px;
  }

  @include media-breakpoint-up(xl) {
    width: 214px;
    height: 348px;
  }
}

.question-status {
  border-radius: 3rem;
  flex: 0 0 auto;
  padding: 0.75rem 1.5rem 0.75rem 1.25rem;
  font-size: 1rem;
  @extend %default-container;

  i {
    position: relative;
    font-size: 1.3125rem;
    color: $primary;
    line-height: 1;
    top: 4px;
    margin-right: 6px;
  }
}

.language-switch {
  color: $body-color;
  transition: $transition-base;

  &:hover {
    text-decoration: none;

    strong {
      color: $headings-color;
    }
  }

  i {
    position: relative;
    font-size: 1.3125rem;
    color: $primary;
    line-height: 1;
    top: 4px;
    margin-right: 6px;
  }
}
