.glossary-fill-in {
  margin: 0;
  padding: 0;

  li {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.35rem 0.4rem 0.35rem 1rem;
    border-radius: 2rem;
    color: $white;
    font-size: 0.9375rem;
    font-weight: bold;
    list-style: none;
    cursor: move;

    &:last-child {
      margin-right: 0;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.17);
      border-radius: 3rem;
      height: 2rem;
      width: 2rem;
      margin-left: 0.55rem;
      cursor: pointer;

      i {
        color: $white;
        font-size: 1rem;
      }
    }
  }
}

#glossary-fill-in-paragraph {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  padding: 0;

  li {
    list-style: none;
    display: inline-flex;

    &.glossary-fill-in__item {
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
      margin-right: 0.4rem;
      margin-left: 0.3rem;
      padding: 0.33rem 1rem;
      border-radius: 2rem;
      color: $white;
      font-size: 0.9375rem;
      font-weight: bold;

      span {
        display: none;
      }
    }
  }
}

.glossary-fill-in-placeholder {
  justify-content: center;
  position: relative;
  display: inline-flex;
  padding-left: 0.2rem;
  background: $gray-50;
  border: 1.4px solid #948787;
  border-radius: 2rem;
  min-width: 72px;
  min-height: 35px;
  //margin-left: 0.20rem;
  margin-right: 0.20rem;
}
