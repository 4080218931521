.select2-selection--multiple {
    background-color: $white;
    min-height:45px;
    line-height: 2;
    border-radius: 8px !important;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    cursor: text;
    outline: 0;
    @include default-inset(-2px);
    
    &:focus {
      border: 1px solid $focus-border-color;
    }
  
    .select2-selection__rendered {
      list-style: none;
      margin: 0;
      padding: 0 5px;
    }
  
    .select2-selection__clear {
      display: none;
    }
  
    .select2-selection__choice {
      background-color: $gray-100;
  
      border: 1px solid $border-color;
      border-radius: $border-radius;
      font-size: 0.866667rem;
      font-weight: 500;
      cursor: default;
  
      float: left;
  
      margin-right: 5px;
      margin-left: 3px;
      margin-top: 8px;
      padding:2px 10px 0px 10px;
    }
  
    .select2-selection__choice__remove {
      float:right;
      padding-left:5px;
      color: $remove-color;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
  
      margin-right: 2px;
  
      &:hover {
        color: $danger;
      }
    }
  }
  
  &[dir="rtl"] {
    .select2-selection--multiple {
      .select2-selection__choice {
        float: right;
        margin-left: 5px;
        margin-right: auto;
      }
  
      .select2-selection__choice__remove {
        margin-left: 2px;
        margin-right: auto;
      }
    }
  }
  
  &.select2-container--open {
    .select2-selection--multiple {
      border: 1px solid $focus-border-color;
    }
  
    &.select2-container--above {
      .select2-selection--multiple {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  
    &.select2-container--below {
      .select2-selection--multiple {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  