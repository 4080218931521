.view-toggler {
  flex: 0 0 auto;

  button,
  a {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0;

    background: $gray-50;
    color: $gray-700;

    &:hover {
      color: $primary;
    }

    &.is-active {
      color: $white;
      background: $tertiary;
      pointer-events: none;
    }

    &,
    &:focus {
      i {
        position: relative;
        top: 1px;
        margin-right: 0.35rem;
        font-size: 1rem;
        line-height: 1;
      }
    }
  }
}

.collapse-toggle {
  position: relative;
  color: $gray-700;
  font-weight: bold;

  &::after {
    content: $icon-chevron-down-outline;
    font-family: $edusmart-font-family;
    position: absolute;
    top: 1px;
    right: -1.4rem;
    font-size: 1.25rem;
    line-height: 1;
  }

  &[aria-expanded="true"] {
    &::after {
      content: $icon-chevron-up-outline;
    }
  }

  &:hover {
    text-decoration: none;
    color: $primary;
  }
}

.collapse-toggler {
  position: relative;
  color: $gray-700;
  font-weight: bold;
  display: flex !important;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: auto;

  &::after {
    content: $icon-chevron-down-outline;
    font-family: $edusmart-font-family;
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 1.5rem;
    line-height: 1;
    transform-origin: center;
    transform: translateY(-50%);
  }

  &[aria-expanded="true"] {
    &::after {
      content: $icon-chevron-up-outline;
    }
  }
}
