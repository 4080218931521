.resource-panel {

    $rp-parent : &;
    height: auto;
    width      : 100%;
    
    &__header {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding        : 1rem;

        h5 {
            margin-bottom : 0;
            padding-bottom: 0;
        }
    }

    &__content {

        padding: 0;

        .default-row {
            margin: 1rem;

            &__introduction {
                font-size    : 0.75rem;
                margin-bottom: 0;
            }

            &__header {
                #{$rp-parent}__checkbox {
                    label {
                        top: 3px;
                    }
                }
            }

            &__features {
                margin-left: 0.5rem;
            }

            &__thumbnail {
                margin-right: 0.5rem;
            }
        }

    }

    &__section-title {
        position        : relative;
        display         : flex;
        justify-content : flex-start;
        align-items     : center;
        background-color: $gray-50;
        padding         : 0.75rem 1rem;
        border-top      : 1px solid $border-color;
        border-bottom   : 1px solid $border-color;
        font-weight     : bold;

        i {
            font-size   : 1.5rem;
            color       : $gray-400;
            margin-right: 0.5rem;
        }
    }

    &__section-footer {
        position       : relative;
        display        : flex;
        flex           : 1 0 100%;
        justify-content: center;
        align-items    : center;
    }

    &__actions {
        position       : relative;
        display        : flex;
        justify-content: flex-end;
        align-items    : center;

        font-size       : 0.875rem;
        font-weight     : bold;
        color           : $gray-700;
        border-top      : 1px solid $border-color;
        border-bottom   : 1px solid $border-color;
        background-color: $gray-50;
        padding         : 0.75rem 1rem;

        span {
            display     : inline-flex;
            align-items : center;
            margin-right: auto;
        }

        i {
            font-size   : 1.5rem;
            color       : $gray-400;
            margin-right: 0.5rem;
        }
    }

    &__tab {
        &-header {
            margin: 0 1rem;
        }
    }

    .nav-tabs {
        position: relative;
        margin  : 0 1rem;

        a {
            display        : flex;
            justify-content: center;
            flex           : 1;
            padding        : 0.75rem 0.25rem;
            text-align     : center;
            z-index        : 1;

            i {

                display  : inline-flex;
                padding  : 0;
                font-size: 1.5rem;
            }

            //span
            .label {
                display: none !important;
            }

        }


        @include media-breakpoint-up(xl) {
            a {
                flex      : 1 0 auto;
                text-align: left;
                font-size : 0.75rem;

                i {
                    &:not(:last-child) {
                        display: none;
                    }
                }

                .label {
                    display: inline-block !important;

                }
            }
        }

        &::before {
            content   : "";
            position  : absolute;
            left      : -1rem;
            right     : -1rem;
            bottom    : -1px;
            // width  : 100%;
            height    : 1px;
            background: $border-color;
            z-index   : 0;

        }
    }

    .tab-content {
        padding: 1rem 0;
    }

}