.list-group {
    $lg-parent: &;


    &.has-attachments {
        #{$lg-parent}-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
                display: flex;
                flex-flow: row nowrap;
                text-decoration: none;
                text-align: center;
                color: $gray-700;
                span {
                    display: block;
                    text-align:left;
                    flex:1;
                }
                img {
                    
                    flex: 0 0 auto;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}