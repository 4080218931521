html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  position: relative;
}

.mfp-bg {
  background-color: $modal-backdrop-bg;
  opacity: $modal-backdrop-opacity;
}

// .container-fluid {
//     // max-width: 100%;
//     max-width   : 1600px; //temporary see how it goes
// }

a {
  transition: $transition-base;
}

.subtitle {
  color: $gray-700;
  font-weight: 500;

  i {
    font-size: 1.25rem;
  }
}

.text-underline {
  text-decoration: underline;
}

// .is-hidden {
//     display:none
// }
@for $i from 1 through 5 {
  .h-#{$i}0 {
    height: $i * 10px !important;
  }

  .w-#{$i}0 {
    width: $i * 10px !important;
  }
}

.h-15 {
  height: 15px !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.lead-icon {
  font-size: 2.5rem;
  color: $primary;
  margin-right: 0.5rem;
}

.lead-icon-sm {
  font-size: 1.75rem;
  color: $primary;
  margin-right: 0.5rem;
}

.regular-icon {
  @extend %default-icon-style;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.attachments-toggler {
  display: block;
  text-align: center;
  width: 100%;
  padding-top: 0.75em;
  font-weight: bold;
  color: $gray-700;
  border-top: 1px solid $border-color;
}

.download-link {
  position: relative;
  font-weight: bold;
  color: $gray-700;
  padding-left: 1.85rem;

  &:hover {
    text-decoration: none;
    color: $primary;

    i {
      color: $primary;
    }
  }

  i {
    position: absolute;
    top: 0;
    left: 0;
    color: $gray-300;
    @extend %default-icon-style;
  }
}

.mobile-only {
  display: inline-flex;
}

@include media-breakpoint-up(md) {
  .mobile-only {
    display: none;
  }
}

.common-standard-list {
  margin: 0;
  padding: 0;
  display: inline-flex;

  li {
    list-style: none;
    margin-right: 0.25rem;
  }
}

.form-selection {
  &--border {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    border: 2px solid $border-color;
    border-radius: $border-radius;
    padding: 0.5rem;
    margin-bottom: 1rem;

    i {
      font-size: 1.25rem;
      color: $gray-300;
      cursor: pointer;
    }

    &.is-active {
      border-color: $primary;
    }

    label {
      top: 5px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}

.default-handle {
  line-height: 1;
  color: $gray-300;
  font-size: 1.25rem;
  margin-right: 0.5rem;
  transition: 200ms;
  cursor: grab;

  &:active,
  &:focus {
    cursor: grabbing;
  }

  &:hover {
    color: $primary;
  }
}

.list-background-on-drag {
  background: rgba($warning, 0.3);
}

.rotate-90deg {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  // height         : 100%;
  text-align: center;
  top: 50%;
  right: 50%;
  transform: scale(-1) translate(-50%, 50%);
  writing-mode: vertical-rl;

  &--center {
    justify-content: center;
  }

  &--left {
    transform: scale(-1) translate(-50%, 0%);
  }
}

.filter-link {
  position: relative;
  font-weight: bold;
  color: $gray-700;
  margin-right: 0.5rem;
  padding-left: 2rem;

  i {
    position: absolute;
    left: 0;
    top: -1px;
    @extend %default-icon-style;
  }
}

.border-top-1 {
  border-top: 1px solid $border-color !important;
}

.border-bottom-1 {
  border-bottom: 1px solid $border-color !important;
}

.go-to-top {
  position: fixed;
  bottom: 3rem;
  right: 0;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  z-index: 999;

  &.is-visible {
    a {
      transform: translateX(0);
    }
  }

  a {
    position: relative;
    border-radius: $border-radius-xl 0 0 $border-radius-xl;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: $tertiary;
    transition: 0.2s;
    transform: translateX(100%);
    @include default-dark-inset(-2px);

    &:before {
      content: "";
      position: absolute;
      top: -50%;
      right: 200%;
      width: 3rem;
      height: 200%;
      background: rgba($white, 0.15);
      transform: rotate(20deg);
    }

    &:hover {
      &:before {
        right: -250%;
        transition: 750ms ease all;
      }
    }

    i {
      @extend %default-icon-style;
      color: $white;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.horiznotal-radio-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  border: 2px solid $border-color;
  border-radius: $border-radius;
  padding: 0.5rem;
  margin-bottom: 1rem;

  i {
    font-size: 1.25rem;
    color: $gray-300;
    cursor: pointer;
  }

  &.is-active {
    border-color: $primary;
  }

  label {
    top: 5px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.alert {
  @include default-dark-inset(-2px);
}

.no-data-present-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../images/background-welcome-page-50.png) no-repeat center
    center;
  padding: 5rem 0;
  background-size: 50%;

  img {
    display: inline-block;
    max-width: 280px !important;
  }
}

.styled-icon {
  @extend %default-icon-style;
  position: absolute;
  top: 2px;
  left: 0;
}

.no-data {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  text-align: center;
  min-height: 320px;
  padding: 2rem;
}

.reporting-table {
  thead {
    tr {
      th {
        border-top: 0 !important;
        border-bottom: 1px solid $border-color !important;
        text-align: center;

        &:first-child {
          // text-align: left !important;
          padding-left: 0.75rem;

          @include media-breakpoint-up(md) {
            padding-left: 1.25rem;
          }
        }

        &:last-child {
          // text-align: right !important;
          padding-right: 0.75rem;

          @include media-breakpoint-up(md) {
            padding-right: 1.25rem;
          }
        }
      }
    }
  }

  tbody {
    tr {
      th {
        vertical-align: middle;
        padding-left: 0.75rem;

        @include media-breakpoint-up(md) {
          padding-left: 1.25rem;
        }
      }

      td {
        text-align: center;

        &:first-child {
          // text-align: left !important;
        }

        &:last-child {
          // text-align: right !important;
          padding-right: 0.75rem;

          @include media-breakpoint-up(md) {
            padding-right: 1.25rem;
          }
        }
      }
    }
  }
}
