body .login {
  margin: 0;
  padding: 0;

  .login-wrapper {
    position: relative;
    padding: 0;
    margin-top: 1rem;
    // flex-wrap: wrap;
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100vh;
      width: 100vw;
    }

    .login {
      &__left {
        display: none;

        @include media-breakpoint-up(lg) {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 1 40%;
          height: 100%;
          background: $light
            url(https://edusmart-lms-assets.s3.amazonaws.com/EdulearnNETUpload/login/lms-login.png)
            left center no-repeat;
          background-size: cover;

          & > div {
            padding: 3.3333rem;
            z-index: 1;
            color: $white;
            font-weight: 500;
            font-size: 1.2rem;
            text-align: center;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            background: rgba(0, 0, 0, 0.5);
          }
        }
      }

      &__right {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 1 1 60%;
      }

      &__form {
        max-width: 28.125rem;
        // min-width: 18.75rem;
        margin-left: 0.625rem;
        margin-right: 0.625rem;
        height: auto;

        // text-align: center;
        img {
          max-width: 13.3333rem;
        }
      }

      &__features {
        display: none;

        @include media-breakpoint-up(md) {
          position: absolute;
          bottom: 1.25rem;
          right: 1.25rem;
          margin: 0;
          padding: 0;
        }

        li {
          display: inline-block;
          list-style: none;
          margin-right: 1rem;

          &:last-child {
            margin-right: 0;
          }

          a {
            font-size: 0.875rem;
            color: $gray-400;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
