.main-navbar {
  position: relative;
  background: $white;
  // box-shadow   : 0 2px 8px 0 rgba(0, 0, 0, 0.10);
  box-shadow: 0 2px 1px 0 rgba($black, 0.1);
  margin-bottom: 20px;
  z-index: 1000;
  padding-top: 0px;
  padding-bottom: 0px;
  .disabled {
    pointer-events: none; //This makes it not clickable
    opacity: 0.6; //This grays it out to look disabled
  }

  .navbar-brand {
    img {
      max-width: 100px;
      height: auto;
    }
  }

  //navbar-brand

  &__search {
    position: fixed;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;

    top: 0;
    left: 0;
    width: 100%;

    transform-origin: center;
    transform: translate(-50%, -50%);
    background: $white;
    padding: 2rem 0 1rem 0;
    box-shadow: $box-shadow-sm;

    opacity: 0;
    visibility: hidden;

    &-close {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 1rem;
    }

    .input-group {
      margin-bottom: 0.5rem;
    }

    &.is-visible {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    }

    @include media-breakpoint-up(lg) {
      flex-flow: row nowrap;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      transition: $transition-base;
      transform: translateY(-10px);

      padding: 1rem 0;
      background: $white;
      border-top: 1px solid $border-color;

      .input-group {
        margin-bottom: 0rem;
      }

      button,
      .btn {
        margin-bottom: 0;
      }

      &-close {
        display: none;
      }
    }
  }

  &__user-data {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    margin-left: 0.6667rem;
    margin-right: 0.335rem;
  }

  &__username {
    font-size: 0.933rem;
    color: $gray-700;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1.5;
  }

  &__user-position {
    font-size: $font-size-sm;
    color: $gray-300;
    line-height: 1;
  }

  .navbar-nav {
    margin-left: auto;

    .nav-item {
      position: relative;
      padding: 0.6rem;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: none;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        padding: 0.2rem;
        border-bottom: none;
      }

      //boostrap core active
      &.active {
        .nav-link {
          color: $primary;

          i {
            color: $primary;
          }
        }
      }

      &:hover {
        .nav-link {
          transition: $transition-base;
          color: $primary;

          i {
            color: $primary;
          }
        }
      }

      .nav-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        color: $gray-700;

        i {
          font-size: 1.5rem;
          // color      : $gray-300;
          line-height: 1;
          padding: 0 6px 0 0;
          margin: 0;
        }

        & > .has-notifcation {
          position: absolute;
          right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          background: $danger;
          font-size: 0.75rem;
          border-radius: 21px;
          font-weight: bold;
          color: $white;
          min-width: 24px;
          min-height: 24px;
          text-align: center;
          line-height: 1;
        }

        &.disabled {
          color: $gray-400;

          i {
            color: $gray-400;
          }

          &:hover {
            color: $gray-400;
          }
        }

        @include media-breakpoint-up(lg) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          & > .has-notifcation {
            top: 11px;
            right: 11px;
            min-width: 21px;
            min-height: 21px;
            border: 2px solid $white;
            font-size: 0.63336rem;
          }
        }
      }

      &.has-children {
        @include media-breakpoint-up(lg) {
          z-index: 998;
          padding-right: 1rem;

          & > .nav-link {
            &::after {
              content: $icon-chevron-down-outline;
              font-family: $edusmart-font-family;
              color: $gray-300;
              font-size: 1.2rem;
              position: absolute;
              top: 50%;
              right: 0;
              transform-origin: center center;
              transform: translateY(-50%);
              line-height: 1;
              margin: 0;
              padding: 0;
            }
          }

          &.is-visible {
            & > .nav-link {
              &::after {
                content: $icon-chevron-up-outline;
              }
            }

            ul.is-child {
              transition: $transition-base;
              transform: translate(0px, 0px);
              opacity: 1;
              visibility: visible;
            }
          }
        }

        ul.is-child {
          position: relative;
          display: flex;
          flex-flow: column wrap;
          visibility: visible;
          opacity: 1;
          margin-left: 0;
          border-radius: $border-radius-sm;

          li {
            list-style-type: none;
            padding-left: 0;

            &:last-child {
              padding-bottom: 0px;
            }

            a {
              font-weight: 500;
              color: $gray-700;
              font-size: 0.85rem;

              &:hover {
                color: $primary;
              }
            }
          }

          @include media-breakpoint-up(lg) {
            position: absolute;
            display: flex;
            flex-flow: column wrap;
            top: 70px;
            left: 0px;
            width: 100%;
            min-width: 260px;
            padding: 8% 0% 8% 0%;
            margin-bottom: 1.3rem;
            transform-origin: left top;
            transform: translate(0px, 20px);
            opacity: 0;
            visibility: hidden;

            background: $white;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
            padding-left: 0;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 50%;
              transform-origin: center top;
              transform: translate(-50%, -9px);
              width: 0;
              height: 0;
              border-left: 9px solid transparent;
              border-right: 9px solid transparent;
              border-bottom: 9px solid $white;
            }

            li {
              &:first-child a {
                padding-top: 1rem;
              }

              &:last-child a {
                padding-bottom: 1rem;
              }

              a {
                padding: 0 0.75rem 0.75rem 1rem;
              }
            }
          }

          //breakpoint
        }

        //ul
      }

      //has-children
    }

    //nav-item
  }

  //navbar-nav

  &__toggler {
    position: relative;
    width: 32px;
    height: 36px;
    padding: 9px;
    margin-right: 10px;
    text-align: center;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $gray-600;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      border-radius: $border-color;
      transition: $transition-base;
      // transform-origin: center center;
    }

    span:nth-child(1) {
      top: 8px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 16px;
    }

    span:nth-child(4) {
      top: 24px;
    }

    &.is-active span:nth-child(1) {
      top: 21px;
      width: 0%;
      left: 50%;
    }

    &.is-active span:nth-child(2) {
      transform: rotate(45deg);
    }

    &.is-active span:nth-child(3) {
      transform: rotate(-45deg);
    }

    &.is-active span:nth-child(4) {
      top: 19px;
      width: 0%;
      opacity: 0;
      left: 50%;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
