.btn,
.btn:focus {
    position     : relative;
    font-family  : "Poppins";
    font-weight  : 600;
    box-shadow   : inset 0 -2px 0 0 rgba(0, 0, 0, 0.25);
    outline      : none;
    border-radius: $border-radius-lg;
    text-align   : center;
    // optional hover effect 
    overflow  : hidden;
    transition: 300ms ease all;

    &:before {
        content   : '';
        position  : absolute;
        top       : -40%;
        right     : 110%;
        width     : 3rem;
        height    : 200%;
        background: rgba($white, .15);
        transform : rotate(20deg);
    }

    &:hover {
        &:before {
            right     : -150%;
            transition: 1s ease all;
        }
    }

    //optional hover effect end.



    &.has-icon-only {
        padding: 0.4625rem 0.725rem;

        i {
            padding    : 0;
            margin     : 0;
            line-height: 1;
        }
    }

    &.has-icon {
        display        : inline-flex;
        align-items    : center;
        justify-content: space-between;

        img {
            max-height  : 2rem;
            margin-right: 0.5rem;
        }

        i {
            font-size   : 1.5rem;
            margin-right: 0.5rem;
        }
    }

    &.has-small-image {
        img {
            max-height: 21px;
        }
    }

    &.is-rounded {
        border-radius: 10rem;
    }

    .bg-average {
        color: $white !important;
    }


}

.btn-rounded {
    // position  : relative;
    width        : 2.05rem;
    height       : 2.05rem;
    text-align   : center;
    padding      : 5px 0 0 0;
    margin       : 0;
    border-radius: 4rem !important;

    // background-color: $gray-200;
    i {
        font-size: 1.25rem;
    }

    &.is-active {
        background: $primary;

        i {
            color: $white;
        }
    }


    span {
        position       : absolute;
        display        : flex;
        justify-content: flex-start;
        align-items    : center;
        left           : 2.5rem;
        top            : 2px;
        height         : 100%;
        font-size      : $font-size-sm;
        text-shadow    : none;

    }

    @include media-breakpoint-up(md) {
        span {
            display: none;
        }

    }
}

.btn-review-assignment {

    img {
        width : 56px;
        height: auto;
    }
}

.blink_me {
    animation: blinker 1s linear 12;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0.5;
    }
  }