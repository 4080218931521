.breadcrumb {
    margin-top      : 1rem;
    margin-bottom   : 0;
    align-items     : center;
    font-size       : 0.875rem;
    font-weight     : bold;
    color           : $gray-700;
    border-top      : 1px solid $border-color;
    border-bottom   : 1px solid $border-color;
    background-color: $gray-50;
    padding         : 0.75rem 1rem;
    border-radius   : 0;

    &-item {
        &.active {
            color: $gray-400;
        }

        &+&::before {
            content    : $icon-chevron-right-outline;
            font-family: $edusmart-font-family;
            position   : relative;
            top        : 3px;
            font-size  : 1rem;
            color      : $gray-400;
            line-height: 1;
        }
    }
}