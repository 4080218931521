.nav-player {
    position       : relative;
    display        : inline-flex;
    flex-wrap      : wrap;
    justify-content: center;
    align-items    : center;
    border-radius  : $border-radius;
    padding        : 0.5rem;
    background     : $white;
    border         : 1px solid $border-color;
    margin-bottom  : 0;

    &.is-fixed {
        position        : fixed;
        display         : flex;
        bottom          : 0px;
        left            : 50%;
        transform-origin: left center;
        transform       : translate(-50%, -50%);
        @include default-large-shadow();

    }

    &__control {
        list-style-type: none;
        list-style     : none;
        margin-right   : 0.3333rem;



        &:last-child {
            margin-right: 0;
        }

        &.is-text {

            &,
            a {
                color          : $gray-400;
                font-weight    : bold;
                text-decoration: none;
            }
        }

        &.is-progressbar {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
                width  : 20rem;
            }

        }

        &.is-active {
            a {
                @extend %is-active;
            }
        }

        &.is-expanded {
            a {
                @extend %is-active;

                i {
                    &::before {
                        content: $icon-collapse-outline;
                    }
                }
            }

        }

        &.is-mutted {
            a {
                @extend %is-active;

                i {
                    &::before {
                        content: $icon-volume-off-outline;
                    }
                }
            }
        }
    }
}