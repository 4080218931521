.help-center {

    .list-group , .list-group-item:last-child {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    &-topics {
        margin-bottom: 1rem;

        .default-container__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

        }
        .help-center__toggler {

            &[aria-expanded="true"] {

                background: $primary;
                color: $white;
                border-color: transparent;
    
                i {
                    &::before {
                        content: $icon-chevron-down-outline;
                    }
                }
            }
    
            &:hover {
    
                &[aria-expanded="true"] {
                    color: $white !important;
                }
            }
        }

        .list-group {
            &-item {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            a {
                font-weight: $font-weight-bold;
            }
            .active {
                color: $primary;
                font-weight: $font-weight-bold;
            }
        }

        a {
            display: block;
            color: $body-color;
            &:hover {
                color: $primary;
                text-decoration: none;
            }
        }
    }
    &-subjects {
        .help-center__toggler {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            
            .h5 {
                transition: color 200ms ease-in-out;
                margin-right: 1rem;
                margin-bottom: 0;
            }
            &[aria-expanded="true"] {
                
                 .h5 {
                     color: $primary;
                 }
                .btn-rounded {
                    background: $primary;
                    color: $white;
                    border-color: transparent;
        
                    i {
                        &::before {
                            content: $icon-chevron-down-outline;
                        }
                    }
                }
    
            }
    
            &:hover {
    
                .h5 {
                    color: $primary;
                }
    
                &[aria-expanded="true"] {
                    .btn-rounded {
                        color: $white !important;
                    }
                }
            }
        }
        .default-container__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .list-group-item {
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            justify-content: flex-start;
            padding-top: 1rem;
            padding-bottom: 1rem;

            .help-center-subjects__title {
                margin-bottom: 1rem;
                flex:1;
            }
            ul {
                display: flex;
                list-style:none;
                margin: 0;
                padding: 0;

                li {
                    margin-right: 0.75rem;
                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        color: $body-color;
                        font-weight: $font-weight-bold;
                        i {
                            color: $gray-500;
                        }
                        &:hover {
                            color: $primary;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }


    @include media-breakpoint-up(lg) {
        &-topics {
            margin-bottom: 0;
            .help-center__toggler {
                display: none;
            }
            .list-group {
                width: 100%;
            }
            .collapse{
                display: flex;
            }
        }
        &-subjects {
            .list-group-item {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;

                ul li a {
                    font-weight: $font-weight-base;
                }
                .help-center-subjects__title {
                    margin-right: 1rem;
                    margin-bottom: 0;
                }
            }
        }
    }
}