.pagination {
    .page-item {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            .page-link {
                background  : $primary;
                border-color: $primary;
            }
        }

        .page-link {
            min-width    : 2.5rem;
            text-align   : center;
            border-radius: $border-radius;
            border-color : $border-color;
        }

    }
}