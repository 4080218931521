.summary-graph {
  $sg-parent: &;
  position  : relative;


  &__header {
    display        : flex;
    flex-flow      : column wrap;
    justify-content: center;
    border-bottom  : 1px solid $border-color;
    padding        : 1rem;



    div {
      flex: 0 0 100%;

      &:first-child {
        margin-bottom: 0.625rem;
      }
    }
  }

  &__content {
    position  : relative;
    display   : flex;
    flex-flow : row nowrap;
    height    : auto;
    overflow-x: auto;
  }

  &__footer {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding        : 1rem 1rem 0 1rem;
  }

  &__column {
    flex            : 1 0 150px;
    display         : flex;
    flex-flow       : column wrap;
    width           : auto;
    border-right    : 1px solid $border-color;
    border-bottom   : 1px solid $border-color;
    width           : 6.5rem;
    background-color: $white;

    &:last-child {
      border-right: 0;
    }

    &:hover {
      background: $gray-50;
    }

    &.is-divider {
      @supports(position:sticky) {
        position: sticky;
        top     : 0;
        left    : 0;
      }

      flex          : 1 0 48px;
      max-width     : 82px;
      z-index       :2;
      background    : $gray-50;
      font-weight   : bold;
      color         : $gray-700;
      border-right  : 1px solid $border-color;
      padding-bottom: 1rem;
    }

    &.is-test {
      #{$sg-parent}__footer {
        background: $light-green;
      }

      #{$sg-parent}__date {
        background     : $white url("../../images/unit-test-icon.png") no-repeat center center;
        background-size: auto 24px;
      }

      #{$sg-parent}__subject {
        color: $primary;
      }


    }

    &-inner {
      position          : relative;
      display           : flex;
      justify-content   : center;
      align-items       : center;
      height            : 100%;
      // padding        : 2rem 2rem 0.5rem 2rem;
    }

    &-header {
      position     : relative;
      flex         : 1 0 60%;
      min-height   : 60%;
      width        : 100%;
      text-align   : center;
      border-bottom: 1px solid $border-color;
    }

    &-footer {
      position  : relative;
      width     : 100%;
      text-align: center;
      padding   : 0.625rem;
      flex      : 1 1 40%;
      min-height: 40%;

    }

    &-date {
      position        : absolute;
      display         : flex;
      justify-content : center;
      align-items     : center;
      flex-flow       : column wrap;
      top             : 0;
      left            : 50%;
      width           : 4.5rem;
      height          : 3rem;
      transform-origin: center center;
      transform       : translate(-50%, -50%);

      i {
        font-size: 1.125rem;
        color    : $gray-400;
      }
    }

    &-data {

      display   : flex;
      flex-wrap : wrap;
      text-align: left;
      flex      : 1;

    }

    &-subject {

      display    : block;
      font-weight: bold;
      font-size  : 0.875rem;
      color      : $gray-700;
      height     : 230px;
      margin-top : 0.5rem;
      //prevent overflow. text truncate
      overflow     : hidden;
      text-overflow: ellipsis;
      white-space  : nowrap;

    }

    &-type {
      flex      : 0 0 100%;
      color     : $gray-400;
      font-size : 0.75rem;
      height    : 200px;
      margin-top: 0.5rem;
      //prevent overflow. text truncate
      overflow     : hidden;
      text-overflow: ellipsis;
      white-space  : nowrap;
    }

    &-details {
      display        : flex;
      flex-flow      : column wrap;
      justify-content: center;
      align-items    : center;
      border-bottom  : 1px solid $border-color;
      padding        : 0.5rem;
      margin-bottom  : 1rem;
      height         : 4rem;
    }

    &-barchart {
      position       : relative;
      display        : flex;
      flex-flow      : column wrap;
      justify-content: flex-end;
      margin         : 0 auto;
      width          : 2rem;
      height         : calc(100% - 7.5rem);
      background     : $gray-100;
      min-height     : 200px;

      &::after {
        content : "";
        position: absolute;
        left    : -25%;
        right   : -25%;
        bottom  : 0;

        height    : 1px;
        background: $gray-200;
      }

    }

    &-score {
      flex           : 0 0 auto;
      display        : flex;
      justify-content: center;
      align-items    : center;
      color          : $white;
      font-weight    : bold;
      text-shadow    : 0 1px 1px rgba($black, 0.32);
      z-index        : 0;
      cursor         : pointer;

      &.bg-excellent:hover {
        background-color: darken($excellent, 3%) !important;
      }

      &.bg-good:hover {
        background-color: darken($good, 5%) !important;
      }

      &.bg-average:hover {
        background-color: darken($average, 5%) !important;
      }

      &.bg-weak:hover {
        background-color: darken($weak, 5%) !important;
      }
    }

    &-score-average {
      position     : absolute;
      bottom       : 0;
      left         : 0;
      right        : 0;
      height       : 8px;
      margin-bottom: -4px;
      width        : 100%;
      z-index      : 1;
      transition   : 0.2s;
      cursor       : help;



      //dot
      &::after {
        content         : "";
        position        : absolute;
        left            : 50%;
        top             : 50%;
        width           : 6px;
        height          : 6px;
        background      : $secondary;
        transform-origin: center center;
        transform       : translate(-50%, -50%);
        border-radius   : 10rem;

      }

      //line
      &::before {
        content : "";
        position: absolute;
        left    : 50%;
        top     : 50%;

        width           : 100%;
        height          : 2px;
        background      : $secondary;
        transform-origin: center center;
        transform       : translate(-50%, -50%);
      }
    }

  }



  @include media-breakpoint-up(lg) {
    &__header {

      flex-flow: row nowrap;
      align-items: center;

      div {
        flex:1;
        margin-bottom: 0 !important;
        & > div {
          margin-bottom: 0;
        }

        &:nth-child(2) {
          text-align:right;
        }
      }
    }
  }

}