.page-loader-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 100%;
    height: 100%;
    z-index:9999;
    background-color: rgba($white, 0.75);

}

.page-loader {
    height           : 32px;
    width            : 32px;
    -webkit-animation: page-loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    animation        : page-loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@keyframes page-loader-5-1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.page-loader::before {
    content          : "";
    display          : block;
    position         : absolute;
    top              : 0;
    left             : 0;
    bottom           : 0;
    right            : auto;
    margin           : auto;
    width            : 8px;
    height           : 8px;
    background       : $primary;
    border-radius    : 50%;
    -webkit-animation: page-loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    animation        : page-loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@keyframes page-loader-5-2 {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
    }

    50% {
        transform: translate3d(24px, 0, 0) scale(.5);
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1);
    }
}

.page-loader::after {
    content          : "";
    display          : block;
    position         : absolute;
    top              : 0;
    left             : auto;
    bottom           : 0;
    right            : 0;
    margin           : auto;
    width            : 8px;
    height           : 8px;
    background       : $average;
    border-radius    : 50%;
    -webkit-animation: page-loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    animation        : page-loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@keyframes page-loader-5-3 {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
    }

    50% {
        transform: translate3d(-24px, 0, 0) scale(.5);
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1);
    }
}

.page-loader span {
    display : block;
    position: absolute;
    top     : 0;
    left    : 0;
    bottom  : 0;
    right   : 0;
    margin  : auto;
    height  : 32px;
    width   : 32px;
}

.page-loader span::before {
    content          : "";
    display          : block;
    position         : absolute;
    top              : 0;
    left             : 0;
    bottom           : auto;
    right            : 0;
    margin           : auto;
    width            : 8px;
    height           : 8px;
    background       : $danger;
    border-radius    : 50%;
    -webkit-animation: page-loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    animation        : page-loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@keyframes page-loader-5-4 {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
    }

    50% {
        transform: translate3d(0, 24px, 0) scale(.5);
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1);
    }
}

.page-loader span::after {
    content          : "";
    display          : block;
    position         : absolute;
    top              : auto;
    left             : 0;
    bottom           : 0;
    right            : 0;
    margin           : auto;
    width            : 8px;
    height           : 8px;
    background       : $success;
    border-radius    : 50%;
    -webkit-animation: page-loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    animation        : page-loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@keyframes page-loader-5-5 {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
    }

    50% {
        transform: translate3d(0, -24px, 0) scale(.5);
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1);
    }
}

