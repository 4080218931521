.modal {

    &-header {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        border-bottom  : 1px solid $border-color;

    }

    &-body {
        .nav-tabs {
            border-bottom: 1px solid $border-color;
        }
    }

    &-content {
        border-bottom: 0;
    }

    &-footer {
        border-top: 0;
    }
}