.popover {
    z-index:10 !important;
    background: $white;
    border    : 1px solid $border-color;
    box-shadow: 0 2px 20px 0 rgba(41, 48, 67, 0.15);
    font-weight: bold;
    color: $gray-700;

    i {
        font-size: 0.875rem;
        color    : $gray-400;
    }
}

i {
    &.is-information{
        @extend %default-icon-style;
    }
}