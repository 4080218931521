.question-list {
  position: relative;
  width: 100%;

  &__nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    // text-align  :center;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background: $white;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.is-correct {
      background: rgba($success, 0.1);
      border: 1px solid $success;
      box-shadow: inset 0 0 0 1px $success;

      transition: $transition-base;
      color: $success;

      .noUi-connects {
        background: $white;
      }
    }

    &.is-incorrect {
      font-weight: bold;
      border: 1px solid $danger;
      box-shadow: inset 0 0 0 1px $danger;
      background: rgba($danger, 0.05);
      transition: $transition-base;
      color: $danger;
    }

    &.has-no-border {
      border: none;
    }

    // @include media-breakpoint-up(lg) {
    //     flex-direction: row;
    // }
  }

  &__header {
    display: inline-flex;
    flex: 1;
    flex-flow: column wrap;
    padding: 0.625rem;
    text-align: center;
    border-bottom: 1px solid $border-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    @extend %default-hover;

    &.has-no-border {
      border-bottom: 0;
      border-radius: $border-radius;
    }

    &.is-stocked {
      flex-flow: column wrap;
      align-items: flex-start;

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }

    @include media-breakpoint-up(lg) {
      text-align: left;
      align-items: center;
      flex-flow: row wrap;
      justify-content: flex-start;

      h5 {
        flex: 1;
        margin: 0;
      }
    }
  }

  &__content {
    position: relative;
    padding: 1rem;

    &.has-columns {
      display: flex;
      flex-flow: column wrap;
    }

    img {
      text-align: center;
      margin: 0 auto;
    }
  }

  &__range-slider {
    max-width: 50%;
  }

  &__output {
    max-width: 50%;
  }

  &__footer {
    flex: 1 1 100%;
    padding: 1rem;
  }

  &__thumbnail {
    flex: 0 0 auto;

    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }
  }

  &__attachment {
  }

  &__title {
    font-size: 1rem;
    font-weight: bold;
    flex: 1;
    margin-bottom: 1rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
      margin-left: 1rem;
      margin-bottom: 0rem;
      flex: 1;

      h5,
      h6 {
        margin-bottom: 0;
      }
    }
  }

  &__features {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin-bottom: 0.625rem;

    li {
      list-style: none;
      margin: 0 1rem 0 0;
      padding: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    h6 {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &__features,
  &__buttons {
    @include media-breakpoint-up(lg) {
      margin-right: 0.625rem;
      margin-left: 1.5rem;
      align-self: center;
    }
  }
}

.question {
  display: flex;
  flex-flow: column wrap;

  &__list {
    &-nav {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 1rem;
      margin-top: 1rem;

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__answer {
    @extend %default-hover;
    // span {
    //     padding-top:3px;
    // }
  }

  &__answer-wrapper {
    display: none;

    &.is-visible {
      display: flex;
    }
  }

  &__header {
    background: $gray-100;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 0.65rem 1rem;

    h5 {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem 1.35rem;
    }
  }

  &__answers {
    padding: 0rem 1rem 0 1rem;

    &-list {
      position: relative;
      width: 100%;
      // margin  : 0 0 1rem 0;
      padding: 0;

      &.has-no-style {
        li {
          list-style-type: none;
          list-style: none;
        }
      }

      li {
        list-style-type: upper-alpha;
        list-style-position: inside;
        padding: 1rem;
        margin-bottom: 0.5rem;
        border: 1px solid $border-color;
        box-shadow: inset 0 0 0 1px $white;
        border-radius: $border-radius;

        &:last-child {
          margin-bottom: 0;
        }

        label {
          font-size: 1rem;
          font-weight: bold;
        }

        &.is-correct {
          font-weight: bold;
          background: rgba($success, 0.1);
          border: 1px solid $success;
          box-shadow: inset 0 0 0 1px $success;

          transition: $transition-base;
          color: $success;

          .progress {
            background-color: $white;
          }
        }

        &.is-incorrect {
          font-weight: bold;
          border: 1px solid $danger;
          box-shadow: inset 0 0 0 1px $danger;
          background: rgba($danger, 0.05);
          transition: $transition-base;
          color: $danger;

          .progress {
            background-color: $white;

            &-bar {
              background-color: $danger !important;
            }
          }
        }
      }

      .progress-wrapper {
        span {
          position: absolute;
          right: 0;
          top: -1.75rem;
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }

    &-controls {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding-left: 0;

      li {
        list-style: none;
        margin-right: 0.625rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__attachment {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    img {
      max-width: 100%;
    }
  }

  &__hint {
    margin-top: 1rem !important;
    background: $gray-50 url(../../images/triangle-pattern.png) repeat-x left
      top;
    background-size: 12px 12px;
    padding: 1.5rem 1rem 0.5rem 1rem;
    // border-top  : 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    p {
      margin-bottom: 0.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    flex-flow: row wrap;

    &__answer-wrapper {
      .question__header {
        border-left: 1px solid $border-color;
      }
    }

    &__answers {
      padding-top: 1rem;
      // padding: 1rem 2rem 0 1rem;

      &-list {
        li {
          padding: 1rem;
          margin-bottom: 0.625rem;
        }
      }
    }

    // &__attachment {
    //     padding: 2rem 1rem 0 2rem;
    // }

    &__hint {
      // padding: 3rem 2rem 2rem 2rem;
    }
  }
}
