$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1367px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
);

$enable-responsive-font-sizes: true;
$transition-base: all 200ms ease-in-out;
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Poppins";

/* #f9f9fa */
$white: #ffffff;
$gray-50: #f9f9f9;
$gray-100: #e7e7e7;
$gray-200: #cfcfcf;
$gray-300: #b8b8b8;
$gray-400: #a0a0a0;
$gray-500: #888888;
$gray-600: #707070;
$gray-700: #585858;
$gray-800: #414141;
$gray-900: #292929;
$black: #111111;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "50": $gray-50,
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #2d65fc;
$indigo: #6e2dfc;
$purple: #b92dfc;
$pink: #e83e8c;
$red: #f51430;
$orange: #fc872d;
$yellow: #fdce5b;
$green: #79c61f;
$teal: #20c997;
$olive: #c6c51f;
$cyan: #17a2b8;
$light-green: #e6f4d6;
$colors: ();

// stylelint-disable-next-line scss/dollar-variable-default

$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
    "light-green": $light-green,
  ),
  $colors
);

// $primary  : #257F52;
// $secondary: #202A51;
$primary: #ff6500;
$secondary: #013299;
$tertiary: #202950;

$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;

$light: $gray-100;
$lightest: $gray-50;
$dark: $gray-800;

$excellent: $green;
$good: $yellow;
$average: $orange;
$weak: $red;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "lightest": $lightest,
    "dark": $dark,
    "excellent": $excellent,
    "good": $good,
    "average": $average,
    "weak": $weak,
    "gray": $gray-100,
    "light-green": $light-green,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 10%;
// // The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// // // Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-600;
$yiq-text-light: $white;

$headings-color: $black;
$body-color: $gray-800;
$body-bg: #f8f8f8;
$border-color: $gray-100;
$border-radius: 12px;
$border-radius-lg: 16px;
$border-radius-xl: 32px;

$font-size-base: 0.9375rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.15;
$font-size-sm: $font-size-base * 0.875;

$input-btn-padding-y: 0.65rem;
$input-btn-padding-x: 1rem;
$input-btn-focus-width: 0;
$input-btn-padding-y-sm: 0.4rem;
$input-btn-padding-x-sm: 0.85rem;
$input-btn-padding-y-lg: 0.85rem;
$input-btn-padding-x-lg: 1.55rem;
$input-font-weight: 500;
$input-placeholder-color: $gray-300;

$spacer: 0.625rem; //10px
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.5,
    ),
    //5px
      2:
      (
        $spacer,
      ),
    //10px
      3:
      (
        $spacer * 2,
      ),
    //20px
      4:
      (
        $spacer * 3,
      ),
    //30px
      5:
      (
        $spacer * 4,
      ),
    //40px
      6:
      (
        $spacer * 5,
      )
      //50px,
  ),
  $spacers
);

// $headings-margin-bottom:      $spacer / 2;
// $headings-font-family  :        null !default;
$headings-font-weight: 600;
$headings-line-height: 1.5;
// $headings-color        :              null !default;

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $gray-700;
$tooltip-opacity: 1;
$tooltip-arrow-width: 0.6rem;
$tooltip-arrow-height: 0.3rem;
$tooltip-border-radius: 6px;
$popover-border-radius: $border-radius/2;

$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 1px;
$nav-tabs-border-radius: 8px;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $white;

$modal-backdrop-bg: rgb(34, 34, 34);
$modal-backdrop-opacity: 0.5;
$modal-inner-padding: 1.5rem;
$modal-fade-transform: translate(0, 20px) scale(0.9);
$modal-show-transform: translate(0, 0) scale(1);

// Progress bars
$progress-height: 0.8rem;
$progress-bg: darken($gray-50, 1%);
$progress-bar-bg: theme-color("success");
$progress-bar-animation-timing: 1.5s linear infinite;
$progress-bar-transition: width 0.6s ease !default;

//input
$input-border-color: $border-color;
$input-box-shadow: inset 0 -2px 0 0 darken($border-color, 4%);
$input-focus-border-color: $border-color;
$input-focus-color: $primary;
$input-transition: $transition-base;
$input-focus-box-shadow: inset 0 -2px 0 0 darken($border-color, 4%);
$input-border-radius: $border-radius-lg * 2;

// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-item-padding-x: 0.75rem;
$component-active-bg: $gray-300;

$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: $gray-200;

//alerts

$alert-color-level: 6;
$alert-bg-level: -6;
$alert-border-level: -6;

$list-group-hover-bg: $light-green;
$list-group-active-color: $primary;
$list-group-active-bg: $light-green;
$list-group-active-border-color: darken($light-green, 6%);

$input-group-addon-bg: $gray-100;
