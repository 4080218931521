.progress-wrapper {
    position: relative;

    span {
        &:first-child {
            font-weight: bold;
        }
    }
}

.progress {
    position     : relative;
    overflow     : auto;
    border-radius: 9999px;
    border       : 1px solid $border-color;
    padding      : 1px;
    overflow     : hidden;

        .progress-bar {
        position: relative;
        font-weight: bold;
        font-size: 0.875rem;
        color: $white;
        text-shadow: 0px 1px 1px rgba($black, 0.55); 


        &:first-child {
            border-top-left-radius   : 9999px;
            border-bottom-left-radius: 9999px;
        }

        &:last-child {
            border-top-right-radius   : 9999px;
            border-bottom-right-radius: 9999px;
        }

        @include default-dark-inset(-3px);

        &.temporary-animation {
            animation-name           : animateWidth;
            animation-duration       : 20s;
            animation-iteration-count: 1;
            // animation-direction   : alternate;
        }
    }


    //child of .progress-wrapper
    &__divider {

        display        : flex;
        justify-content: space-around;
        align-items    : flex-start;
        padding        : 0.5rem 0 0 0;
        color          : $gray-400;
        font-size      : 0.875rem;

        span {
            font-size  : 0.8125rem;
            font-weight: 500;
            flex       : 2;
            text-align : center;

            &:first-child {
                flex      : 1;
                text-align: left;
            }

            &:last-child {
                flex      : 1;
                text-align: right;
            }
        }
    }

    &__time-marker {
        position     : absolute;
        left         : 50%;
        top          : 20%;
        transform    : translate(-50%, -50%);
        box-shadow   : inset 0 -2px 0px rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        width        : 2.25rem;
        height       : 2.25rem;
        background   : $white;
        border-radius: 4rem;
        transition   : $transition-base;
        text-align   : center;


        i {
            line-height: 2.15rem;
            color      : $gray-300;
            font-size  : 1.25rem;
        }

        img {
            padding-top: 4px;
            max-width  : 75%;
        }

        &.has-divider {
            top: 20%;
        }

        &::after {
            content         : attr(data-perfect-timing);
            position        : absolute;
            top             : -1.15rem;
            left            : 50%;
            transform       : translateX(-50%);
            transform-origin: center center;
            color           : $gray-700;
            font-size       : 0.85rem;
            font-weight     : bold;
            width           : 200px;

            @include media-breakpoint-up(lg) {
                font-size: 1rem;
                top      : -1.5rem;
            }
        }

    }

}

@keyframes animateWidth {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}