// Remember to add this class to body tag to override existing css classes for Universe Effect.
$universe-primary-bg            : #1A466F;
$universe-darker-bg             : #002A53;
$universe-primary-border-color  : $universe-primary-bg;
$universe-secondary-border-color:#4A81B5;
$universe-border-size           : 2px;

.edusmart-universe-theme {


    $border-radius: 16px;
    $border-radius-lg: 32px;

    background-color: #002A53;
    // background           : #002A53 url(../../../images/themes/universe/bg.jpg) no-repeat center top;
    background-size      : cover;
    background-attachment: fixed;
    color                : $white;
    min-height: calc(100vh - 321125rem);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: $white;
    }
    .btn {
        border-radius: $border-radius-lg;
    }
    ul {

        padding: 0;

        li {
            list-style-type    : circle;
            list-style-position: inside;

        }
    }

    .modal-body {
        color: $body-color;
    }

    .subtitle {
        color: rgba($white, 0.75) !important;
    }


    label {
        color: $white !important;
    }

    input,
    input:active,
    input:focus {
        color: $secondary !important;
    }

    .astronaut-bg {
        &::after {
            content   : "";
            position  : absolute;
            bottom    : 0;
            right     : 16px;
            width     : 89px;
            height    : 90px;
            z-index   : 0;
            background: url(../../../images/themes/universe/astronaut-2.svg) no-repeat;
        }
    }

    .spaceship-bg {
        &::after {
            content   : "";
            position  : absolute;
            bottom    : 0;
            right     : 16px;
            width     : 89px;
            height    : 94px;
            z-index   : 0;
            background: url(../../../images/themes/universe/space-ship-4.svg) no-repeat;
        }
    }

    .universe-border {
        border       : 2px solid $universe-primary-bg;
        border-radius: $border-radius;
    }

    .page-main {

        &__header {

            .h3,
            h3,
            .h4,
            h4 {
                color: $white;
            }

            .subtitle {
                color: rgba($white, 0.75);
            }
        }

        &__controls {

            display        : flex;
            align-items    : center;
            justify-content: center;
        }

        @include media-breakpoint-up(lg) {

            &__controls {
                justify-content: flex-end;
            }
        }
    }

    .edusmart-user-id {
        $usi-parent    : &;
        position       : relative;
        display        : flex;
        justify-content: flex-start;
        align-items    : center;
        border-radius  : $border-radius;
        background     : rgba($universe-primary-bg, 0.9) url(../../../images/themes/universe/edusmart-shape.svg) no-repeat right bottom;
        border         : $universe-border-size solid $universe-secondary-border-color;
        padding        : 1.25rem 1rem 1rem 1rem;
        margin-top     : 1.25rem;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &::after {
            content         : "";
            position        : absolute;
            left            : 50%;
            top             : -1.3rem;
            transform       : translateX(-50%);
            transform-origin: center center;
            background      : url(../../../images/themes/universe/clip.svg) no-repeat center top;
            background-size : 100%;
            width           : 30px;
            height          : 37px;
        }

        &__photo {
            display        : flex;
            width          : 3rem;
            height         : 3rem;
            border-radius  : $border-radius-lg;
            background     : $universe-darker-bg;
            background-size: cover;
            margin-right   : 1rem;
        }

        &__details {
            display  : flex;
            flex-flow: column wrap;
            flex     : 1;

            .h5,
            .h6,
            h5,
            h6 {
                margin-bottom: 0;
                color        : $white;
            }
        }

        &__barcode {
            background: url(../../../images/themes/universe/barcode.svg) no-repeat left 2rem center;
        }


        &-selection {
            display        : flex;
            flex-flow      : row wrap;
            justify-content: center;
            color          : $white;
            font-size      : 0.75rem;
            text-align     : center;
            max-width      : 130px;
            height         : 150px;
            margin-left    : auto;
            margin-right   : auto;
            cursor         : pointer;
            transition     : all 200ms ease-in-out;

            &:hover {
                transform: translateY(-4px);
            }

            .edusmart-user-id__photo-wrapper {
                padding      : 0.5rem;
                background   : rgba($universe-darker-bg, 0.75);
                border-radius: $border-radius-lg*2;
                text-align   : center;
                margin       : 0 auto;

            }

            .edusmart-user-id__photo {
                background         : $universe-darker-bg;
                border-radius      : $border-radius-lg;
                margin             : 0 auto;
                background-size    : 75%;
                background-position: center center;
                background-repeat  : no-repeat;

            }

            &--selected {
                border-color: $success;

                .universe-student-id__photo-wrapper {

                    animation-duration       : 2s;
                    animation-direction      : alternate;
                    animation-iteration-count: infinite;
                    animation-name           : pulsing;
                    animation-fill-mode      : both;
                    animation-timing-function: ease-in-out;


                }
            }
        }
    }

    .list-group {
        &-item {
            background: $universe-primary-bg;
            border    : 2px solid $universe-primary-border-color;
            transition: $transition-base;

            span,
            a {
                color: $white;
            }

            &:hover {
                border-color: $universe-secondary-border-color;
            }
        }
    }

    .thumbnail {
        background: none;
    }

    /* Student timeline */

    .student-timeline {
        display        : flex;
        justify-content: flex-start;
        align-items    : stretch;

        &__line {
            position    : relative;
            width       : 3.375rem;
            margin-right: 1.5rem;

            background         : linear-gradient(0deg, rgba(0, 42, 83, 1) 0%, rgba(0, 42, 83, 0) 50%, rgba(0, 42, 83, 1) 100%), url(../../../images/themes/universe/universe-line.svg);
            background-repeat  : repeat-x, repeat-y;
            background-size    : auto, 4px 20px;
            background-position: center;
        }

        &__wrapper {
            position: relative;
            width   : 100%;
        }

        //holds everything up
        &__section {
            flex         : 1;
            display      : flex;
            margin-bottom: 3rem;

            &:nth-child(1n) {
                .student-timeline__aside {
                    background-image: url(../../../images/themes/universe/planet-1.svg);
                }
            }

            &:nth-child(2n) {
                .student-timeline__aside {
                    background-image: url(../../../images/themes/universe/planet-2.svg);
                }
            }

            &:nth-child(3n) {
                .student-timeline__aside {
                    background-image: url(../../../images/themes/universe/planet-3.svg);
                }
            }

            &:nth-child(4n) {
                .student-timeline__aside {
                    background-image: url(../../../images/themes/universe/planet-4.svg);
                }
            }

            &:nth-child(5n) {
                .student-timeline__aside {
                    background-image: url(../../../images/themes/universe/planet-5.svg);
                }
            }

            &:nth-child(6n) {
                .student-timeline__aside {
                    background-image: url(../../../images/themes/universe/planet-6.svg);
                }
            }

            &:nth-child(7n) {
                .student-timeline__aside {
                    background-image: url(../../../images/themes/universe/planet-7.svg);
                }
            }

        }

        //planets
        &__aside {
            position           : absolute;
            top                : 0;
            left               : -4.825rem;
            width              : 3.375rem;
            height             : 3.375rem;
            background-size    : cover;
            background-position: center center;
            background-repeat  : no-repeat;
            background-image   : url(../../../images/themes/universe/planet-1.svg);
        }

        //content - rows etc 
        &__content {
            position     : relative;
            width        : 100%;
            padding      : 1.25rem;
            border       : $universe-border-size solid $universe-primary-border-color;
            border-radius: $border-radius;
            color        : $white;

            //statues
            &--in-progress {
                box-shadow  : 0 0 16px $warning;
                border-color: $warning;
            }

            &--incompleted {
                box-shadow  : 0 0 16px $danger;
                border-color: $danger;
            }

            &--completed {
                box-shadow  : 0 0 16px $success;
                border-color: $success;
            }

            &--borderless {
                border : 0;
                padding: 0;
            }


            &-header {
                display  : flex;
                flex-flow: column wrap;


                @include media-breakpoint-up(lg) {
                    flex-flow      : row nowrap;
                    justify-content: space-between;
                    align-items    : center;
                }
            }

            &-title {
                flex          : 1;
                margin-bottom : 1rem;
                padding-bottom: 1rem;
                border-bottom : 2px solid $universe-primary-border-color;


                h4,
                .h4 {
                    color: $white;
                }

                .subtitle {
                    color: rgba($white, 0.75);
                }

                @include media-breakpoint-up(lg) {
                    margin-right  : 1rem;
                    margin-bottom : 0;
                    padding-bottom: 0;
                    border        : 0;
                }
            }

            &-controls {
                display        : flex;
                justify-content: space-between;
                align-items    : center;

                a {
                    color: $white;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @include media-breakpoint-up(lg) {
                    justify-content: flex-end;
                }
            }
        }
    }

    .default-row {
        display  : flex;
        flex-flow: row wrap;

        border    : 2px solid $universe-primary-border-color;
        background: transparent;

        &--borderless {
            border: 0;
        }

        &__date {
            width       : 55px;
            height      : 55px;
            margin-right: 2.5rem;

            .universe-astronaut {
                position         : relative;
                width            : 100%;
                height           : 100%;
                background       : url(../../../images/themes/universe/astronaut.svg);
                background-repeat: no-repeat;


                .flag {
                    top : 0;
                    left: 40px;

                    &__pole {
                        height: 55px;
                    }
                }
            }
        }

        &__header {
            border-color       : $universe-primary-border-color;
            border-bottom-width: 2px;
        }

        &__title {
            color: $white !important;
        }

        &__activity-type {
            i {
                color: $info;
            }

            span {
                color: $white;
            }
        }

        &__content {
            color: $white;


            .question__answers {
                padding : 0;
                position: relative;
                width   : 100%;

                &-list {
                    padding: 1rem 0 0 0;

                    li {
                        border    : 1px solid $universe-primary-border-color;
                        box-shadow: inset 0 0 0 1px $universe-primary-border-color;

                        &:hover {
                            background  : transparent;
                            border-color: $universe-secondary-border-color;
                            box-shadow  : inset 0 0 0 1px $universe-secondary-border-color;
                            color       : $universe-secondary-border-color;
                        }
                    }
                }
            }

        }

        &__footer {
            border-color: $universe-primary-border-color;
            border-width: 2px;

            p:last-child {
                margin-bottom: 0;
            }
        }

        &__introduction {
            flex: 1;

            margin-bottom: 1rem;
        }

        &__description,
        &__introduction {

            h6,
            .h6 {
                font-size: 1.125rem;
            }

            &>* {
                color: $white;
            }

            .subtitle {
                color: rgba($white, 0.75);
            }
        }

        &__features {
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            flex-flow      : column wrap;


            .btn {
                margin-bottom: 1rem;


                &:last-child {
                    margin-right: 0;
                }
            }

            @include media-breakpoint-up(md) {
                flex-flow: row wrap;
                width    : auto;

                .btn {
                    margin-bottom: 0;
                    margin-right : 1rem;
                }
            }
        }

        &__score {
            display  : flex;
            flex-flow: row wrap;

            margin : 0 0 2rem 0;
            padding: 0;

            li {
                display     : flex;
                align-items : center;
                margin-right: 1.25rem;
                list-style  : none;

                i {
                    font-size   : 1.5rem;
                    color       : $info;
                    font-family : $edusmart-font-family;
                    margin-right: 1rem;
                }

                .h5,
                h5 {
                    color        : $white;
                    margin-bottom: 0;
                }

                span {
                    color: rgba($white, 0.75);
                }
            }

            @include media-breakpoint-up(md) {

                margin-bottom: 0;
            }
        }

    }

    .toggle-btn {
        flex         : 0;
        font-size    : 1.3125rem;
        color        : $white;
        font-family  : $edusmart-font-family;
        padding      : 0.75rem;
        background   : $universe-primary-bg;
        border-radius: $border-radius;
        margin-left  : 1rem;

        &:hover {
            text-decoration: none;
        }

        &[aria-expanded="true"] {
            i {
                &::before {
                    content: $icon-chevron-up-outline;
                }
            }
        }
    }

    .nav-tabs {
        margin-top: 2rem;

        .nav-item {
            position        : relative;
            background-color: #0F3B63;
            border-top      : 2px solid transparent;
            border-left     : 2px solid transparent;
            border-right    : 2px solid transparent;
            box-shadow      : 0;

            &.nav-link {
                box-shadow: none !important;
                color     : $white;
                border    : 2px solid transparent;
            }

            &.active {
                background   : transparent;
                color        : $info;
                border       : 2px solid $universe-primary-border-color;
                border-bottom: 0;
                box-shadow   : none !important;

                &::before {
                    content : "";
                    position: absolute;
                    bottom  : -2px;

                    background: #002A53;
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            border                : 2px solid $universe-primary-border-color;
            border-radius         : $border-radius;
            border-top-left-radius: 0;
            padding               : 1rem;
        }
    }

    .printables {
        border-top: 2px solid $universe-primary-border-color;
        padding   : 1rem;
    }

    .lesson-stats {
        &__list {
            display      : inline-flex;
            flex-flow    : row wrap;
            background   : transparent;
            padding      : 1rem;
            border       : 2px solid $universe-primary-border-color;
            border-radius: $border-radius;


            li {
                display     : flex;
                align-items : center;
                margin-right: 1.25rem;
                list-style  : none;

                &:last-child {
                    margin-right: 0.25rem;
                }

                i {
                    font-size   : 1.5rem;
                    color       : $info;
                    font-family : $edusmart-font-family;
                    margin-right: 1rem;
                }

                .h5,
                h5 {
                    color        : $white;
                    margin-bottom: 0;
                }

                span {
                    color: rgba($white, 0.75);
                }
            }

            @include media-breakpoint-up(md) {

                margin-bottom: 0;
            }
        }
    }

    .question-card {
        display        : flex;
        justify-content: center;
        align-items    : stretch;

        color: $white;

        input[type='radio'],
        input[type='checkbox'] {
            display: none; //hide by default
            cursor : pointer; //make the cursor a pointer when over the items

            &:focus,
            &:active {
                outline: none; //remove the annoying outline
            }

            &+label {
                cursor         : pointer;
                display        : flex;
                justify-content: center;
                align-items    : center;
                width          : 100%;
                position       : relative;

                font-size    : 1rem;
                font-weight  : bold !important;
                text-align   : center;
                padding      : 1rem;
                border       : 2px solid $universe-primary-border-color;
                border-radius: $border-radius;


                &:before {
                    display: none !important;
                }

                &:after {
                    display: none !important;
                }
            }

            &:checked+label {
                color: $info !important;

                border-color: $info;
            }
        }


        &--correct {

            input[type='radio'],
            input[type='checkbox'] {

                &:checked+label {

                    border-color: $success;
                    color       : $success !important;
                    font-weight : bold !important;

                }

            }
        }

        &--incorrect {

            input[type='radio'],
            input[type='checkbox'] {

                &:checked+label {

                    border-color: $danger;
                    color       : $danger !important;
                    font-weight : bold !important;
                }

            }
        }


        @include media-breakpoint-up(lg) {
            min-height: 180px;
        }
    }

    .question-block {
        position     : relative;
        margin-bottom: 2rem;
        margin-left  : auto;
        margin-right : auto;

        background   : $universe-primary-bg;
        border-radius: $border-radius;
        padding      : 1rem 1.5rem;

        .h3,
        .h4,
        h3,
        h4 {
            margin-bottom: 0;
            text-align   : center;
        }
    }

    .flag {
        display : flex;
        width   : 56px;
        height  : 48px;
        position: absolute;
        top     : -15px;
        left    : 45%;


        &__material {
            width        : 36px;
            height       : 32px;
            background   : $white;
            border-radius: 2px;

            &-header {
                background-color       : $primary;
                color                  : $white;
                font-size              : 0.45rem;
                padding                : 0.15rem 0 0 0;
                text-shadow            : 0 1px 0 0 rgba($gray-800, 0.1);
                text-align             : center;
                font-weight            : bold;
                text-transform         : uppercase;
                border-top-left-radius : 2px;
                border-top-right-radius: 2px;
            }

            &-content {
                font-size  : 0.875rem;
                color      : $secondary;
                text-align : center;
                font-weight: bold;
            }
        }

        &__pole {
            position     : relative;
            height       : 100%;
            width        : 3px;
            background   : #556080;
            border-radius: 3px;
            z-index      : 1;

            &::before {
                content      : "";
                z-index      : 0;
                position     : absolute;
                bottom       : 0;
                left         : 0;
                width        : 3px;
                height       : 3px;
                border-radius: 3px;
                background   : rgba($gray-800, 0.5);
            }
        }
    }

    .rocket {
        width                    : 52px;
        height                   : 124px;
        background               : url(../../../images/themes/universe/rocket.svg) no-repeat center center;
        background-size          : 52px 124px;
        position                 : absolute;
        bottom                   : 100px;
        left                     : -1px;
        // animation-duration       : 3s;
        // animation-direction      : alternate;
        // animation-iteration-count: infinite;
        // animation-name           : floatingRocket;
        // animation-fill-mode      : both;
        // animation-timing-function: ease-in-out;
    }

    .progress-wrapper {
        position  : relative;
        margin-top: 1rem;

        span {
            display        : flex;
            position       : relative;
            width          : 100%;
            justify-content: flex-end;
            margin-bottom  : 0.5rem;
        }

        .progress {
            background: transparent;
            border    : 2px solid $universe-primary-border-color;

            &__divider {
                margin-top: 0.5rem;
                color     : $white;
            }
        }

        @include media-breakpoint-up(lg) {
            span {
                justify-content: flex-end;
            }
        }
    }

    .quiz-summary {
        padding        : 1rem;
        border-radius  : $border-radius;
        background     : $universe-primary-bg url("../../../images/themes/universe/solar-system.png") no-repeat -80px -75px;
        background-size: 225px 225px;

        .list-group {

            &-item {
                background: transparent;
                border    : none;

                &:hover {
                    border: none;
                }
            }

            .badge-adaptive {
                background: $universe-darker-bg;
            }
        }
    }

    .summary-graph {
        border-radius: $border-radius;
        width: 100%;
        background: none;
        padding-left: 15px;
        padding-right: 15px;
        

        &__column {
            background: none;
            color: $white;
            border: none;
            margin-right: 1rem;
            border-radius: $border-radius;
            background: $universe-primary-bg;
        }

        &__column {
            &-details, &-header {
                border-color: $universe-darker-bg;
                border-width: 2px;
            }
            &-subject {
                color: $white;
            }
            &-type {
                color: rgba($white, 0.75);
            }
            &-barchart {
                background-color: rgba($universe-darker-bg, 0.5);
            }
        }
    }


    @keyframes floatingRocket {

        25% {
            transform: translate(0px, -10px);
        }

        50% {
            transform: translateX(-5px);
        }

        75% {
            transform: translateX(5px);
        }

        100% {
            transform: translate(0px, 10px);
        }

    }

    .falling-star {
        z-index: 9;
        width  : 190px;
        height : 1px;
        background: linear-gradient(135deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 1) 33%,
                rgba(255, 255, 255, 0) 100%);
        position                 : absolute;
        border-radius            : 50%;
        animation-duration       : 5.25s;
        animation-iteration-count: infinite;
        animation-name           : falling;
        animation-timing-function: cubic-bezier(0.33, 0.19, 0.26, 1.3);
        top                      : -20px;
        left                     : 50%;
        transform-origin         : center center;
        transform                : translate(-50%, -50%);
        transform                : rotate(-45deg) translate3d(0, 0, 0);
        will-change              : transform, width, opacity;

        &:nth-of-type(1) {
            right          : 40px;
            animation-delay: 8.2s;
        }

        &:nth-of-type(2) {
            right                    : 200px;
            animation-delay          : 11s;
            animation-iteration-count: 4;
        }

        &:nth-of-type(3) {
            right             : -180px;
            animation-duration: 3s;
            animation-delay   : 2.8s;
        }

        @include media-breakpoint-up(lg) {
            left: 75%;
        }
    }

    @keyframes falling {

        60%,
        100% {
            transform: rotate(-40deg) translate3d(-560px, 0, 0);
            opacity  : 0.5;
            width    : 0;
        }
    }

    @keyframes pulsingPadding {
        100% {
            transform: scale(1.1);
        }
    }
}