.test-listing {
 
    .breadcrumb {
        margin-bottom: 0;
    }

    .default-row {
        &__content {
            padding:1.25rem;
            justify-content: space-between;

            h5, .h5 , h6, .h6 {
                margin-bottom: 0;
                
            }
        }
    }

    &__features {
        a:first-child {
            margin-right: 0.5rem;
        }
    }
    @include media-breakpoint-down(sm) {
        &__title {
            width:100%;
            margin-bottom: 0.5rem;
        }
    }
    

}