.table-bordered thead th {
    border-bottom-width: 0px;
}

.table {
    tr {
        &:hover {
            background: $gray-50;
        }

        td {
            text-align    : center;
            vertical-align: middle;

            .keys {
                &__item {
                    span {
                        margin-right : 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

//calender in workspace area
.fc-list-item-title {
    text-align: left !important;
}

//file:///Users/marcin/Desktop/Projekty/EduSmart/bitBucket_edusmart/dist/review-progress-quiz.html

.summary-table {
    $st-parent: &;
    position  : relative;

    i {
        @extend %default-icon-style;
    }

    &__header {
        display      : flex;
        flex-flow    : column wrap;
        border-bottom: 1px solid $border-color;
        padding      : 1rem;

        div {
            flex: 0 0 100%;

            &:first-child {
                margin-bottom: 0.625rem;
            }
        }
    }
    &__content {
        display           : flex;
        overflow-x        : auto;
        // border-bottom  : 1px solid $border-color;
    }
    &__footer {
        padding: 1rem 1rem 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__inner {
        position       : relative;
        display        : flex;
        justify-content: center;
        align-items    : center;
        height         : 100%;
        padding        : 1rem;
    }

    &__column {
        flex         : 1 0 150px;
        border-right : 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        background   : $white;

        &:not(:first-child):hover {
            background-color: $gray-50;
        }

        &:last-child {
            border-right: 0;
        }

        &.is-divider {
            @supports(position:sticky) {
                position: sticky;
                top     : 0;
                left    : 240px;
            }
            
            flex: 1 0 48px;
            max-width: 82px;
            z-index     :2;
            background  : $gray-50;
            font-weight : bold;
            color       : $gray-700;
        }

        &.is-lead {
            @supports(position:sticky) {
                position: sticky;
                top     : 0;
                left    : 0;
            }

            max-width: 240px;
            flex     : 1 0 240px;
            z-index  : 10;

            #{$st-parent}__column {
                &-cell {
                    position       : relative;
                    justify-content: flex-start;
                    font-weight    : bold;
                    // width       : 100%;

                    .avatar__name {
                        //only inline-block;
                        display      : inline-block;
                        white-space  : nowrap;
                        overflow     : hidden;
                        text-overflow: ellipsis;
                    }
                }

            }
        }

        &-header {
            border-bottom  : 1px solid $border-color;
            min-height     : 90px;
            display        : flex;
            flex-flow      : row wrap;
            justify-content: center;
            align-items    : center;
            font-size      : 1rem;

            .small {
                margin-bottom: auto;
            }

            .h6 {
                text-align: center;
                margin-bottom: 0;
            }

        }

        &-footer {
            display        : flex;
            justify-content: center;
            align-items    : center;
            min-height     : 5rem;


            .calendar-icon {
                margin-right: 0 !important;
            }

        }

        &-activity-type {
            margin-top : auto;
            width      : 100%;
            color      : $gray-700;
            text-align : center;
            font-weight: bold;
            cursor     : help;
        }

        &-label {
            position     : relative;
            min-width    : 100px;
            background   : $gray-50;
            border-bottom: 1px solid $border-color;
            border-right : 1px solid $border-color;

            span {
                display: inline-block;
                width  : 320px;

                font-weight: bold;
                color      : $gray-700;

            }
        }

        &-cell {

            display        : flex;
            justify-content: center;
            align-items    : center;
            border-bottom  : 1px solid $border-color;
            min-height     : 64px;
            padding        : 0.625rem;
            font-size      : 0.875rem;



        }
    }

    //column

    @include media-breakpoint-up(lg) {
        &__header {
            flex-flow      : row nowrap;
            justify-content: space-between;
            align-items    : center;

            .form-group {
                max-width: 240px;
            }

            div {
                flex: 1;

                &:nth-child(1) {
                    margin-bottom: 0;
                }

                &:nth-child(2) {
                    display        : flex;
                    justify-content: flex-end;

                }
            }
        }
    }
}