/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .openEndedQuestionModalButton {
        margin-right: 30px;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .openEndedQuestionModalButton {
        margin-right: 30px;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .openEndedQuestionModalButton {
        margin-right: 30px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .openEndedQuestionModalButton {
        margin-right: 10px;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .openEndedQuestionModalButton {
        margin-right: 0px;
    }
}