.available-tests {
    $at-parent     : &;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    background     : $gray-50 url(../../images/triangle-pattern.png) repeat-x left top;
    background-size: 12px 12px;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 0;
    margin : 0;

    &__item {
        position          : relative;
        display           : flex;
        justify-content   : flex-start;
        align-items       : center;
        width             : 100%;
        border-bottom     : 1px solid $border-color;
        list-style        : none;
        list-style-type   : none;
        margin-bottom     : 1.25rem;
        // padding        : 1.5rem 1rem 1rem 1rem;
        padding-right     : 1rem;
        padding-left      : 1rem;
        padding-bottom    : 1rem;

        &:first-child {
            padding-top: 1.5rem;
        }

        &:last-child {
            border-bottom    : 0;
            margin-bottom    : 0;

            // padding-bottom: 0;
        }

        &:hover {
            #{$at-parent}__icon {
                transform: scale(1.2);
            }

            #{$at-parent}__title {
                h5 {
                    color: $primary;
                }
            }
        }
    }

    &__icon {
        width       : 4rem;
        margin-right: 1.25rem;
        text-align  : center;
        transition  : 0.3s;

        img {
            height: 3rem;
        }
    }

    &__title {
        h5 {
            transition   : 0.3s;
            margin-bottom: 0;
        }
    }

    &__features {
        margin-left: auto;

        .btn {
            margin-left: 0.625rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

}