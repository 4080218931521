@each $color, $value in $theme-colors {
    .btn-glow-#{$color} {
      @include button-variant($value, $value);
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.20), 0 0 15px 0 $value, inset 0 -2px 0 0 rgba(0,0,0,0.25);
      background-color: $value;
      border-radius: $border-radius-lg;

    }
  }
  
  @each $color, $value in $theme-colors {
    .btn-glow-outline-#{$color} {
      @include button-outline-variant($value);
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.20), 0 0 15px 0 $value, inset 0 -2px 0 0 rgba(0,0,0,0.25);
    }
  }
  