.swal {
    font-family: "Poppins";
}

.swal-overlay {
    background: rgba(32, 41, 80, 0.50);
}

.swal-button {
    border-radius: $border-radius;
    background   : $primary;
    outline      : none !important;

    border    : none !important;
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 transparent !important;

    &:hover {
        background: darken($primary, 4%) !important;
    }
}