.rating-stars {
  position: relative;
  display: inline-block;
  width: 57px;
  height: 15px;
  overflow: hidden;
  margin: 0;
  padding: 0;

  background: url(../../images/rating-stars-sprite.png) no-repeat;
  background-size: 92px auto;
  background-position: 0 0;

  &--1 {
    background-position: 0 -15px;
  }
  &--2 {
    background-position: 0 -30px;
  }
  &--3 {
    background-position: 0 -45px;
  }
}
