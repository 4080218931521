%is-active {
  background: $primary;
  color: $white;

  i {
    color: $white;
  }
}
%default-hover {
  transition: $transition-base;
  &:hover {
    background: $gray-50;
    // box-shadow: inset 0 0 0 3px $white;
  }
}
%default-icon-style {
  font-size: 1.5rem;
  color: $gray-300;
}

%default-container {
  background: $white;
  border: 1px solid $border-color;
  box-shadow: 0 1px 0 0 $border-color;
  border-radius: 12px;

  // background: #FFFFFF;
  // box-shadow: 0 3px 6px -2px rgba(51,51,51,0.30);
  // border-radius: 12px;
}
