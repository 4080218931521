.lesson-player {
  .page-wrapper {
    display: flex;

    .off-canvas {
      position: relative;
      display: flex;
      flex-flow: column wrap;
      min-height: calc(100vh - 57px); //- navbar height
      border-right: 1px solid $border-color;
      background: $white;
      z-index: 0;
      flex: 0 0 auto;

      // OFFCANVAS
      &.is-fullwidth {
        max-width: 21rem;

        .off-canvas {
          &__link span,
          &__features,
          &__title {
            display: none;
          }
        }

        @include media-breakpoint-up(lg) {
          .off-canvas {
            &__link span,
            &__features,
            &__title {
              display: flex;
            }

            &__toggler {
              i {
                &::before {
                  content: $icon-chevron-left-outline;
                }
              }
            }
          }
        }
      }

      //minified
      &:not(.is-fullwidth) {
        max-width: 4.75rem;

        .finish-lesson {
          width: 2.25rem;
          padding: 0.4375rem 0;
          border-radius: 6rem;

          span {
            display: none;
          }
        }

        .off-canvas {
          &__item {
            .icon-list {
              i {
                padding-right: 0;
              }

              span {
                display: none;
              }
            }
          }

          &__toggler {
            margin-left: 0.2rem;

            i {
              &::before {
                content: $icon-chevron-right-outline;
              }
            }
          }

          &__header {
            width: auto;
            justify-content: center;
          }

          &__link span,
          &__features,
          &__title {
            display: none;
          }
        }
      }

      &__header {
        flex: 0 0 auto;
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;

        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: space-between;
          padding: 1rem 1rem 0.6325rem 1rem;

          h4 {
            margin-bottom: 0;
          }
        }
      }

      //ul
      &__content {
        // flex        :1;
        // flex        :  0 0 auto;
        margin: 0;
        padding-top: 0.3675rem;
        padding-left: 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
          padding-top: 0.75rem;
        }
      }

      &__footer {
        flex: 0 0 auto;
        margin-top: auto;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        margin-left: auto;
        margin-right: auto;

        .finish-lesson {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 2.25rem;
          padding: 0.4375rem 0;
          border-radius: 6rem;

          span {
            display: none;
          }

          i {
            font-size: 1.25rem;
            line-height: 1;
            margin: 0;
          }

          @include media-breakpoint-up(lg) {
            width: auto;
            border-radius: $btn-border-radius;
            padding: 0.65rem 1.35rem;

            span {
              display: inline-block;
              line-height: 1;
              margin-left: 0.3rem;
            }
          }
        }
      }

      &__title {
        text-align: left;
        display: flex;
        flex-flow: column wrap;
        max-width: 200px;
      }

      &__toggler {
        i {
          &::before {
            content: $icon-chevron-left-outline;
          }

          @include media-breakpoint-up(lg) {
            &::before {
              content: $icon-chevron-right-outline;
            }
          }
        }
      }

      //li
      &__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        overflow: hidden;
        // flex        : 0 0 auto;
        margin-left: 0.3125rem;
        margin-bottom: 0.3rem;
        margin-right: 0.3125em;
        background: $white;
        border-radius: $border-radius / 1.25;
        border: 1px solid $border-color;
        box-shadow: inset 0 0 0 1px $border-color;
        // max-width   : 21rem;

        padding: 0.1875rem;

        &.is-active {
          border: 1px solid $primary;
          box-shadow: inset 0 0 0 1px $primary;
        }

        &.is-break {
          justify-content: center;
          text-align: center;
          padding: 1rem;
          margin-bottom: 0 auto 0.3rem auto;
          background: $white url("../../images/stripes-bg.svg") repeat-x left
            top;

          &:hover {
            background: $white url("../../images/stripes-bg.svg") repeat-x left
              top;
          }

          .icon-list {
            li {
              i {
                color: $gray-400;

                @include media-breakpoint-down(lg) {
                  padding: 0;
                }
              }

              span {
                display: none;

                @include media-breakpoint-up(lg) {
                  display: inline-flex;
                }
              }
            }
          }

          //icon-list
        }

        &.is-finished {
          background: rgba($success, 0.2);
          border: 1px solid $success;
          box-shadow: inset 0 0 0 1px $success;

          .off-canvas__activity-icon {
            background: #e5f4d8;
          }
        }

        &.is-in-progress {
          background: rgba($warning, 0.2);
          border: 1px solid $warning;
          box-shadow: inset 0 0 0 1px $warning;

          .off-canvas__activity-icon {
            background: #fff5e0;
          }
        }

        &.is-lesson-plan {
          .off-canvas__thumbnail {
            min-height: 49px;
            padding: 0.4rem 0.25rem 0.25rem 0.25rem;
          }
        }

        &:hover {
          border: 1px solid $primary;
          box-shadow: inset 0 0 0 1px $primary;
          background: $white;
          transition: $transition-base;

          .off-canvas {
            &__activity-icon {
              background: $white;
            }

            &__link {
              color: $primary;
            }
          }
        }
      }

      &__thumbnail {
        position: relative;
        // min-width: 3.75rem;
        // padding  : 0.2rem;
        overflow: hidden;

        img {
          max-width: 100%;
        }
      }

      &__activity-icon {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 26px;
        height: 26px;
        background: $white;
        border-radius: 4px 0px 3px 0px;
        z-index: 3;

        &:hover {
          i {
            color: $primary;
          }
        }

        i {
          position: relative;
          left: 3px;
          top: 2px;
          display: inline-block;
          font-size: 1.3333rem;
          color: $gray-400;
          text-align: center;
        }
      }

      &__link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: row nowrap;
        color: $gray-700;
        font-weight: bold;
        font-size: 0.8rem;

        &:hover {
          text-decoration: none;
        }

        span {
          display: inline-block;
          margin-left: 0.5rem;
          text-align: left;
          max-width: 12rem;
        }
      }

      //features right hand side
      &__features,
      &__timer {
        i {
          font-size: 1.25rem;
          color: $gray-400;
        }
      }

      &__features {
        margin-left: auto;
        padding-left: 1rem;

        a {
          line-height: 1;

          &:hover {
            text-decoration: none;

            i {
              color: $primary;
            }
          }
        }
      }

      &__timer {
        font-size: 0.7rem;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0.3333rem;

        i {
          margin-right: 0.1rem;
        }
      }

      //offcanvas
      // from 992px
      @include media-breakpoint-up(lg) {
        min-height: calc(100vh - 66px); //- navbar height

        &.is-fullwidth {
          width: auto;
        }
      }
    }
  }
}

//page-wrapper
