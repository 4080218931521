.admin {
    $admin-parent: &;


    &-dashboard {
        &__item {
            position       : relative;
            display        : flex;
            flex-flow      : row wrap;
            justify-content: center;
            align-items    : space-around;
            min-height: 160px;
            padding      : 0.75rem;
            transition   : $transition-base;
            margin-bottom: 1.25rem;

            .admin-dashboard__icon {
                display        : flex;
                justify-content: center;
                align-items    : center;
                width          : 100%;

                font-family: $edusmart-font-family;
                font-size  : 2.5rem;
                color      : $primary;
                padding    : 0.5rem 0;
            }
            h5 , .h5 {
                font-size:1rem;

            }
            &:hover {
                text-decoration: none;
                box-shadow     : 0 2px 0 0 $border-color, $box-shadow;

                .h5 {
                    color: $primary;
                }
            }
        }

        &__label {
            display   : block;
            text-align: center;

            span {
                display: block;
                width  : 100%;
            }

            .h5 {
                line-height: 1.2;
                transition   : $transition-base;
            }
        }

        @include media-breakpoint-up(sm) {
            &__item {
                margin-bottom: 1.75rem;
                min-height   : 180px;

                h5, .h5 {
                    font-size: 1.0625rem;
                }
                .admin-dashboard__icon {
                    font-size: 3.25rem;
                    padding  : 0.75rem 0;
                }
            }
        }
    }

    //dashboard

    &__aside {
        .default-container__header {
            position: relative;
            cursor  : pointer;

            &:not(.collapsed) {

                .admin__aside-toggler {
                    &::after {
                        content: $icon-chevron-down-outline;
                    }
                }
            }

            &.collapsed {

                .admin__aside-toggler {
                    &::after {
                        content: $icon-chevron-up-outline;
                    }
                }
            }

            .admin__aside-toggler {
                position        : absolute;
                top             : 50%;
                right           : 10px;
                transform-origin: center;
                transform       : translateY(-50%);
                width           : 32px;
                height          : 24px;


                &::after {
                    content    : $icon-chevron-up-outline;
                    font-family: $edusmart-font-family;
                    font-size  : 1.5rem;
                    position   : absolute;
                    top        : 0;
                    right      : 0;
                    line-height: 1;
                }

                @include media-breakpoint-up(lg) {
                    top: 67%;
                }
            }
        }

        .h5,
        .return-link {
            position     : relative;
            padding-left : 2rem;
            max-width    : 92%;
            margin-bottom: 0;

            i {
                @extend %default-icon-style;
                position        : absolute;
                top             : 50%;
                left            : 0;
                transform-origin: center;
                transform       : translateY(-50%);
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 1rem;
            }
        }

        .list-group {
            &-item {
                border-color: $border-color;
                background  : transparent;

                a {
                    color: $gray-700;

                    &:hover {
                        color: $primary;
                    }
                }

                &.active {
                    font-weight: $font-weight-bold;

                    a {
                        color: $primary;
                    }
                }
            }
        }

    }

    &-alphafilter {

        margin : 0;
        padding: 0;

        li {
            position     : relative;
            display      : inline-flex;
            list-style   : none;
            margin-right : 0.25rem;
            margin-bottom: 0.75rem;

            &:last-child {
                margin-right: 0;
            }

            .btn-rounded {

                padding: 0;

                &.is-h-fluid {
                    width        : auto;
                    padding-left : 1rem;
                    padding-right: 1rem;
                }

                &.is-active {
                    background: $primary;
                    color     : $white;
                    @include default-dark-inset(-2px);
                }
            }

        }

    }

    table {
        tr {
            td {
                text-align: left;
            }
        }
    }

    &-active-users {
        position: relative;
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        height:100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        .calendar-icon {
            margin-bottom: 1.25rem;
            transform: scale(1.55);
        }
        h3, .h3 {
            margin-bottom: 0;
        }
    }
}
