.myErrStyle {
  color: red;
  float: right;
  font-size: 13px;
  padding-right: 2%;
}

.mySpanStyle {
  padding-left: 6%;
  color: red;
  font-size: 10px;
}

.myLegendStyle {
  padding: 5px;
  color: white;
  font-size: 11px;
  font-weight: 600;
}
