.common-state-standard-filters {
    position: relative;
    width: 100%;
    overflow-x:hidden;
    .form-row {
        border-top: 1px solid $border-color;
        margin-top: 1rem;
        // margin-bottom: 1rem;
        padding:0.75rem 1.25rem 0 1.25rem;
    }

}