.searchbar {

    display: flex;
    align-items: center;
    max-width: 100%;
    flex:1 1 auto;
    margin-bottom: 1rem;

    label {
        position: relative;
        top:2px;
        height: 100%;
        margin-bottom: 0;
        padding-left: 0.5rem;
        i {
            @extend %default-icon-style;
        }
    }
    &__field {
        flex: 1 0 auto;
        border:0;
        background: transparent;
        box-shadow:none;
        outline:0;
        border-radius: 0;
        padding: $input-btn-padding-y $input-btn-padding-x;
        &::placeholder {
            color: $gray-300;
        }
        &:active, &:focus {
            border:0;
            box-shadow: none;
            outline: 0;
        }
    }
    &__submit {
        margin-right: 0.25rem;
    }
    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
        // max-width: 30rem;
        flex:1;
        margin-right: 1rem;

    }
}