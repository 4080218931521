.calendar-icon {

    position       : relative;
    background     : url("../../images/calendar-icon.png") no-repeat left top;
    background-size: cover;
    height         : 54px;
    width          : 49px;
    flex: 0 0 auto;
    
    &__month {
        position: relative;
        display: block;
        width: 89%;
        margin: 0 auto;
        text-align: center;
        top:4px;
        font-weight: bold;
        font-size     : 0.72rem;
        text-transform: uppercase;
        margin-bottom: 0.125rem;
        color         : $white;
        letter-spacing: 0;
        line-height   : 14px;
        text-shadow   : 0 1px 0 rgba(0, 0, 0, 0.45);
        // background: $primary;
        // padding-bottom: 0.25rem;
        // border-top-left-radius: 4px;
        // border-top-right-radius: 4px;

    }

    &__day {
        position: relative;
        display: block;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        font-size: 1.225rem;
        font-weight: bold;
        color: $gray-700;
    }



}