.user-list,
.element-list {
  $usl-parent: &;
  margin-top: 1.25rem;

  &--ids {
    .user-list__name {
      .checkmark {
        top: 14px !important;
      }
    }
  }

  &__header {
    padding: 1rem 0.75rem;
    width: 100%;
    border-radius: 0.75rem 0.75rem 0 0;

    label {
      font-size: 1rem;
      font-weight: bold !important;
      display: flex !important;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0;
    }
  }

  &__content {
    width: 100%;
  }

  &__name {
    display: flex;
    align-items: center;
    width: 100%; //check out

    .checkmark {
      top: 12px !important;
    }
  }

  &__remove {
    margin-left: auto;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem;

    &:hover {
      #{$usl-parent}__remove {
        opacity: 1;
        pointer-events: all;
      }
    }

    label {
      display: flex !important;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0;
    }

    .avatar {
      margin-right: 0.625rem;
    }

    &.is-available {
      background: $gray-50;
    }
  }

  &__actions {
    padding: 1rem 0.75rem;
    border: 1px solid $border-color;
    border-top: none;

    label {
      margin-bottom: 0;
    }
  }

  &__user {
    position: relative;
    color: $gray-700;
    font-size: 0.875rem;
  }

  &__user-id {
    width: 100%;
    font-size: 0.875rem;
    color: $gray-600;
  }

  // &__footer {}

  //styling for students groups
  &.card-columns {
    // margin-top: 1.25rem;
    .card,
    .user-list__item {
      padding: 0;
    }

    .user-list__header {
      border-bottom: 1px solid $border-color;
      background: $gray-50;
    }

    .user-list__user {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5rem 0.75rem;
      border-bottom: 1px solid $border-color;
      transition: 200ms;

      &:hover {
        background: $gray-50;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  //border styling for student LIST
  [class^="col-"] {
    border: 1px solid $border-color;
    transition: 200ms;

    &:hover {
      background: $gray-50;
    }
  }

  &.is-fullwidth {
    [class^="col-"] {
      // border:none !important;
      border-radius: 0 !important;
      border: 1px solid $border-color !important;

      &:first-child() {
        border-bottom: none !important;
        border-top-right-radius: $border-radius !important;
        border-top-left-radius: $border-radius !important;
      }

      &:last-child() {
        border-bottom-left-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;
      }

      &:not(:last-child()) {
        border-bottom: 0 !important;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    column-count: 2;
  }

  @include media-breakpoint-up(lg) {
    column-count: 3;

    &__remove {
      margin-left: auto;
      transition: 0.2s;
      opacity: 0;
      pointer-events: none;
    }
  }
}
