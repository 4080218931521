.fc-today {
    background: $gray-100;
}

.fc-day-header {
    padding: 0.625rem !important;
}

.fc-day-number {
    font-size: 0.875rem;
}

.fc-content {
    padding: 5px;
}

.fc-list-heading {
    background-color: $gray-50;
}


//class name regarding event/class status
.is-in-progress {
    border-color: #FDCE5B !important;

    .fc-content {
        background: #FEF1D2;

    }

}

.fc-next-button,
.fc-prev-button {

    span {

        position: relative;
        padding : 0.65rem;

        &::before {
            content         : "";
            position        : absolute;
            top             : 51%;
            left            : 50%;
            transform       : translate(-50%, -50%);
            transform-origin: center center;
            font-family     : $edusmart-font-family;
            font-size       : 1.5rem;
            line-height     : 1;
            padding         : 0;
            margin          : 0;
        }
    }
}

.fc-next-button {
    span {
        &::before {
            content: $icon-chevron-right-outline;
        }
    }
}

.fc-prev-button {

    span {
        &::before {
            content: $icon-chevron-left-outline;
        }
    }
}

@include media-breakpoint-down(sm) {

    .fc-toolbar {
        display  : flex;
        width    : 100%;
        flex-wrap: wrap;

        .fc-left,
        .fc-center,
        .fc-right {
            width: 100%;
            flex : 0 0 100%;
        }

        .fc-left {
            margin-bottom: 0.625rem;
        }

        .fc-right,
        .fc-left {
            text-align: center;
        }
    }
}

// .fc-dayGridMonth-button,
// .fc-timeGridWeek-button,
// .fc-listWeek-button {


// }