body .lesson-player {

    .page-main {
        flex   : 1;
        padding: 1rem;

        &__header {
            position       : relative;
            display        : flex;
            justify-content: flex-start;
            align-items    : flex-start;
            flex-flow      : column wrap;
            padding-bottom : 1rem;
            width          : 100%;

            h4,
            h5 {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                flex-flow      : row nowrap;
                justify-content: space-between;
                align-items    : center;
                padding-bottom : 1.5rem;

                p {
                    margin-bottom: 0;
                }
            }

        }

        &__content {
            position: relative;
            //will take all available space
            flex      : 1;
            width     : 100%;
            // padding: 0 0.66665rem 0 0.66665rem;

            @include media-breakpoint-up(lg) {
                // padding: 0 2rem 0 2rem;
            }

        }

        &__footer {
            position  : relative;
            text-align: center;
            width     : 100%;
            margin    : 1.3333rem auto;
        }


        @include media-breakpoint-up(lg) {
            padding  : 2rem;
            // height: calc(100vh - 67px) !important;
        }
    }



    .default-container__header {
        border-bottom: 1px solid $border-color;
    }
}