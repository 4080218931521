.teacher-class {
    $tc-parent : &;
    position   : relative;
    display    : flex;
    flex-wrap  : wrap;
    align-items: center;
    width      : 100%;
    padding    : 1rem;

    a {
        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }

    &.is-subgroup {
        margin       : 2rem;
        border       : 1px solid $border-color;
        border-radius: $border-radius;
        background   : $white;
        padding      : 1.25rem 1.25rem 0 1.25rem;
    }

    &__name,
    &__student-count,
    &__task,
    &__features {
        position     : relative;
        display      : flex;
        align-items  : center;
        max-width    : 100%;
        margin-bottom: 1rem;
    }
    &__name {
        flex           : 1 1 auto;
        font-size      : 1.0625rem;
        font-weight    : bold;
        color          : $gray-700;
        // text-decoration: underline;
        margin-right   : 0.75rem;

        img {
            max-height  : 2.25rem;
            margin-right: 0.5rem;
            width       : auto;
        }

        &:hover {
            color: $primary;
        }
    }

    &__student-count {
        flex           : 0 0 auto;
        justify-content: flex-end;
    }

    &__details {
        display: flex;
        align-items: center;
    }

    &__task {
        justify-content: space-between;
        flex           : 1 0 100%;

        &-subject {
            position    : relative;
            width       : 100%;
            margin-right: 1rem;

            // .h6,
            // h6 {
            //     margin-bottom: 0;
            // }


        }

        .btn {
            flex: 0 0 auto;
        }

        .avatar {
            margin-right: 0.625rem;
        }

        &-dropdown {

            display    : flex;
            align-items: center;

            #{$tc-parent}__task {
                &-subject {

                    .h6,
                    h6 {
                        overflow     : hidden;
                        text-overflow: ellipsis;
                        white-space  : nowrap;
                        max-width    : 220px;
                    }
                }
            }

            .btn-rounded {
                i {
                    font-size: 1.25rem;

                    &::before {
                        color: $gray-700;

                    }
                }
            }
        }
    }

    &__features {
        flex         : 1 0 100%;
        margin-bottom: 0;
    }


    .dropdown-item {

        &:focus,
        &:active {
            background: $white;
        }
    }

    .dropdown-toggle::after {
        display: none;
    }

    @include media-breakpoint-up(md) {

        &__task {
            max-width: auto;
            &-dropdown {
                #{$tc-parent}__task {
                    &-subject {

                        .h6,
                        h6 {
                            max-width: 480px;
                        }
                    }
                }
            }
        }
    }


    @include media-breakpoint-up(lg) {
        display        : flex;
        flex-wrap      : nowrap;
        justify-content: space-between;

        &__name,
        &__student-count,
        &__task,
        &__features {
            display      : inline-flex;
            align-items  : center;
            margin-bottom: 0;
        }
        &__details {
            min-width: 20%;
        }
        &__name {
            // flex        : 0 0 auto;
            margin-right: 1rem;
            // min-width   : 15%;
            // max-width   : 240px;
        }

        &__student-count {
            margin-right: 1rem;
        }

        &__task {
            position    : relative;
            flex        : 1;
            margin-right: 1.5rem;
            // max-width: 35%;

            &-subject {

                h6,
                .h6 {
                    max-width: 75%;
                }
            }

            &-answer-count {
                position   : absolute;
                right      : 0;
                top        : 0;
                font-weight: bold;
                font-size  : 0.875rem;
                color      : $gray-500;
            }

            &-dropdown {

                display    : flex;
                align-items: center;

                #{$tc-parent}__task {
                    &-subject {
                        max-width: 480px;

                        .h6,
                        h6 {
                            overflow     : hidden;
                            text-overflow: ellipsis;
                            white-space  : nowrap;
                            max-width    : 75%;
                            margin-bottom: 0;
                        }
                    }
                }

            }
        }

        &__features {
            flex: 0 0 auto;
        }

    }
}

.class-list-thead {
    display: none;
    padding-bottom: 1rem;
    border-bottom: 1px solid $border-color;
    width: 100%;
    margin:0 auto 1rem auto;
    font-weight: bold;

    i {
        position: absolute;
        left:0;
        top:-1px;
        @extend %default-icon-style;
    }
    span {
        margin-left: 1rem;
    }
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.class-list-subgroup {

    &:first-child {
        padding: 1.5rem 1rem 0.25rem 1rem;
    }

    padding: 0 1rem 0.25rem 1rem;
    width  : 100%;

    .default-row {
        transition: $transition-base;

        &:hover {
            box-shadow: 0 2px 0 0 $border-color;
        }
    }
}