.keys {
    margin : 0;
    padding: 0;

    li, &__item {
        position    : relative;
        display     : inline-block;
        list-style  : none;
        // padding     : 1rem 0 0 0;
        margin      : 0;
        font-size   : 0.75rem;
        color       : $gray-300;
        margin-right: 1rem;
        font-weight : bold;

        &:last-child {
            margin-right: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        span {
            position     : relative;
            top          : 1px;
            padding-top  : 2px;
            display      : inline-block;
            width        : 0.75rem;
            height       : 0.75rem;
            background   : $excellent;
            border-radius: 2rem;
            margin-right : 0.5rem;
            @include default-dark-inset(-2px);


            &.is-excellent {
                background: $excellent;
            }

            &.is-good {
                background: $good;
            }

            &.is-average {
                background: $average;
            }

            &.is-weak {
                background: $weak;
            }

            &.is-inactive {
                background: $light;
            }
        }
    }
}