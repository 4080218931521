//look at _main-catagory-navbar.scss too.
.off-canvas-category {
  $occ-parent: &;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  flex: 0 1 21rem;
  min-width: 21rem;
  max-width: 21rem;
  z-index: 100;
  background: $white;
  border-right: 1px solid $border-color;
  min-height: 100vh;
  transition: all 200ms ease-in-out;

  &.is-hidden {
    left: -21rem;

    #{$occ-parent}__toggler {
      left: 100%;
      border-bottom: 0;
      border-bottom-right-radius: $border-radius;

      i {
        &::before {
          content: $icon-chevron-right-outline;
        }
      }
    }
  }

  &__toggler {
    position: absolute;
    top: 0;
    right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3.25rem;
    text-decoration: none !important;
    background: rgba($tertiary, 0.98);

    i {
      color: $white;
      font-size: 1.5rem;
    }

    &:hover {
      background-color: lighten($tertiary, 6%);
    }
  }

  &__filter {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid $border-color;
    padding: 0.625rem;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $primary;
      }

      i {
        position: relative;
        top: -1px;
        margin-right: 0.25rem;
        font-size: 1.25rem;
        line-height: 1;
      }
    }
  }

  &__create-new-lesson {
    position: relative;
    border-bottom: 1px solid $border-color;
    padding: 0 0.5rem 0.5rem 0.5rem;
    text-align: center;

    a,
    button {
      width: 80%;
    }
  }

  &__header {
    background: $tertiary;
    // width        : 100%;
    margin-bottom: auto;
    border-radius: 0 $border-radius $border-radius 0;
    margin-right: 0.5rem;

    label,
    h5,
    h6 {
      color: $white !important;
    }

    &-form {
      padding: 0.625rem 1rem 1rem;
    }

    &-profile {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 1rem;

      .avatar {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 0 0 70%;

        &__image {
          margin-bottom: 0.625rem;
        }

        &__details {
          text-align: center;
          color: $white;

          h5,
          .h5 {
            color: $white;
            margin-bottom: 0;
          }

          .subtitle {
            color: rgba($white, 0.75);
          }
        }
      }
    }

    &-title {
      padding: 0.75rem 0;
      display: flex;
      align-items: center;
      // margin-bottom : 0.75rem;
      border-bottom: 1px solid rgba($white, 0.1);
      // padding-bottom: 0.625rem;
      padding-left: 1rem;

      h5,
      h6 {
        margin-bottom: 0;
      }

      i {
        font-size: 1.5rem;
        color: $gray-300;
        margin-right: 0.5rem;
      }
    }
  }

  &__content {
    // padding   : 0.625rem 0;
    width: 100%;
    // overflow-y: auto;
  }

  &__footer {
    padding: 0.625rem;
    width: 100%;
  }

  &__playlist {
    margin: 0 0 0.5rem 0;
    padding: 0;

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      background: $white;
      border-bottom: 1px solid $border-color;
      padding: 0.625rem 0.625rem 0 0.625rem;
      margin-left: 0;
      overflow-x: hidden;
      list-style: none;
      list-style-type: none;

      // &:first-child {
      //     padding-top: 0.125rem;
      // }

      .h6,
      h6 {
        max-width: 65%;
        margin-bottom: 0;
        margin-right: 0;
      }

      .subtitle {
        margin-bottom: 0.75rem;
      }

      @include media-breakpoint-up(lg) {
        .off-canvas-category__playlist-remove {
          a {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
          }
        }

        &:hover {
          .off-canvas-category__playlist-remove {
            a {
              opacity: 1;
              visibility: visible;
              pointer-events: auto;
            }
          }
        }
      }
    }

    &-remove {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform-origin: left center;
      transform: translateY(-50%);

      // margin-left: auto;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: $gray-300;
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.25),
      inset -2px -2px 2px rgba(0, 0, 0, 0.25);
    margin-right: 12px;
    margin-left: 12px;
    padding: 0 20px;

    &:hover {
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from($primary),
        to(darken($primary, 5%))
      );
    }
  }

  @include media-breakpoint-up(lg) {
    @supports (position: sticky) {
      position: sticky;
      top: 0;
    }

    min-height: 100vh;
    height: 100%;

    &__content {
      overflow-y: auto;
    }

    &.is-hidden {
      margin-left: -21rem;
    }

    &-playlist {
      & > .off-canvas-category__content {
        // overflow-y: auto;
        height: 400px;
      }
    }
  }
}

.playlist-widget {
  $pw-parent: &;
  position: fixed;
  top: 160px;
  right: 1rem;
  z-index: 9;
  height: auto;
  transition: all 300ms ease-in-out;

  &__toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 160px;
    right: 1rem;
    z-index: 9;
    cursor: pointer;
    background: $primary;
    // width          : 2.5rem;
    // height         : 2.5rem;
    padding: 0.75rem 1.25rem;
    border-radius: $border-radius-lg * 2;
    box-shadow: inset 0 -2px 2px 0 rgba(0, 0, 0, 0.2),
      0 5px 10px 0 rgba(0, 0, 0, 0.2);
    transition: all 300ms ease-in-out;

    .badge {
      position: absolute;
      width: 22px;
      height: 22px;
      top: -5px;
      right: -5px;
      transform: scale(1);
      transform-origin: center;
      transition: $transition-base;
      // opacity:1;
      visibility: visible;

      &.is-hidden {
        transform: scale(0);
        // opacity:0;
        visibility: hidden;
      }
    }

    &-label {
      color: $white;
      font-weight: $font-weight-bold;
      display: block;
      margin-left: 0.35rem;
    }

    &--pulse {
      &::after {
        content: "";
        animation: playlistTogglerAnim 1.5s infinite ease-in-out both;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: $primary;
        border-radius: $border-radius-lg * 3;
      }
    }

    &:hover {
      background-color: darken($primary, 3%);
    }

    @keyframes playlistTogglerAnim {
      0% {
        transform: scaleX(1) scaleY(1);
        opacity: 1;
      }

      100% {
        transform: scaleX(1.25) scaleY(1.5);
        opacity: 0;
      }
    }
  }

  &__list {
    position: absolute;
    top: 110%;
    right: 0;
    height: auto;
    width: 320px;
    background: $white;
    border-radius: $border-radius;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: $transition-base;
    box-shadow: 0 2px 20px 0 rgba(41, 48, 67, 0.15);
    pointer-events: none;

    ul {
      margin: 0;
      padding: 0;
    }

    &.is-visible {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid $border-color;
    padding: 0.625rem;

    &:last-child {
      border-bottom: none;
    }

    .default-handle {
      position: relative;
      top: 3px;

      i {
        color: $gray-300;
      }
    }

    &-remove {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform-origin: left center;
      transform: translateY(-50%);

      i {
        color: $body-color !important;
        margin-right: 0;
      }

      .btn-rounded {
        text-align: center;
        padding-top: 0;

        i {
          padding-top: 6px;
          // padding-left: 3px;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 0.25rem 1rem;
  }

  &__footer {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: space-between;
    padding: 0.5rem 1rem 1rem 1rem;

    .btn,
    button {
      flex: 1;
    }
  }

  &__description {
    max-width: 75%;

    h6,
    .h6 {
      margin-bottom: 0;
    }
  }

  &.is-sticky {
    top: 60px;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-lg * 2;
    font-size: 1.3125rem;
    color: $white;
  }

  @include media-breakpoint-up(sm) {
    top: 118px;

    &__list {
      width: 360px;
    }

    &__footer {
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  @include media-breakpoint-up(lg) {
    top: 129px;

    &__item {
      &-remove {
        a {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }
      }

      &:hover {
        #{$pw-parent}__item {
          &-remove {
            a {
              opacity: 1;
              visibility: visible;
              pointer-events: auto;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    top: 149px;
  }
}
