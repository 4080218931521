
  .select2-selection--single {
    display: flex;
    justify-content: flext-start;
    align-items: center;
    border: 1px solid $border-color;
    outline: 0;
    height:45px;
    min-width: 175px;
    border-radius: 8px;
    @include default-inset(-2px);
    


    &:focus {
      border: 1px solid $focus-border-color;
      
    }

    .select2-selection__rendered {
      color: $gray-700;
      font-weight: 500;
      padding-left:0.625rem;
      padding-right: 2rem;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 10px;
    }

    .select2-selection__placeholder {
      color: $gray-500;
    }

    .select2-selection__arrow {


      height: auto;
      position: absolute;

      top: 6px;
      right: 10px;
      width: 20px;
      &::after {
        content:$icon-chevron-down-outline;
        font-family: $edusmart-font-family;
        font-size: 1.3333rem;
        color: $gray-400;
      }

    }


  }



  &[dir="rtl"] {
    .select2-selection--single {
      .select2-selection__clear {
        float: left;
      }

      .select2-selection__arrow {
        border: none;
        border-right: 1px solid $border-color;

        border-radius: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        left: 1px;
        right: auto;
      }
    }
  }

  &.select2-container--open {
    .select2-selection--single {
      border: 1px solid $focus-border-color;

      .select2-selection__arrow {
        background: transparent;

        border: none;

        b {
          border-color: transparent transparent #888 transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }

    &.select2-container--above {
      .select2-selection--single {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

      }
    }

    &.select2-container--below {
      .select2-selection--single {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
